import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchFiltersService {
  private filtersOpen = new BehaviorSubject<boolean>(false);
  filtersOpen$ = this.filtersOpen.asObservable();
  
  // Add an event emitter for outside clicks
  outsideClick = new EventEmitter<void>();

  toggleFilters() {
    this.filtersOpen.next(!this.filtersOpen.value);
  }
  
  closeFilters() {
    this.filtersOpen.next(false);
  }

  openFilters() {
    this.filtersOpen.next(true);
  }
}