import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { RouterModule } from '@angular/router'; 
import { SearchSharedModule } from 'src/app/search/search/search-shared.module';
//import { SearchModule } from 'src/app/search/search/search.module';
//import { SharedModule } from 'src/app/shared/shared.module';
//import { InpSingleComponent } from 'src/app/search/comp/input/inp-single/inp-single.component';


@NgModule({
  declarations: [FooterComponent],
  exports: [FooterComponent], // Export so other modules can use it
  imports: [
    CommonModule,
    RouterModule,
    SearchSharedModule
    //SharedModule,
    //SearchModule
  ]
})
export class FooterModule {}