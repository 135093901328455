import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Article } from 'src/app/articles/services/article.service';
import { SwiperDirective } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-article-slider',
  templateUrl: './article-slider.component.html',
  styleUrls: ['./article-slider.component.scss']
})
export class ArticleSliderComponent implements OnInit {
  @Input() articles: Article[] = [];
  @Input() limit: number = 5;
  @ViewChild(SwiperDirective) swiper: SwiperDirective;

  config: object = {
    slidesPerView: '3',
    spaceBetween: 12,
    loop: true, 
    breakpoints: {
      480: {
        slidesPerView: 1
      },
      640: {
        slidesPerView: 2
      },
      1024: {
        slidesPerView: 3
      }
    }
  };

  selectedArticles: Article[] = [];
  currentIndex = 0;
  

  constructor() { }

  ngOnInit(): void {
    this.selectArticles();
  }

  selectArticles(): void {
    this.selectedArticles = this.articles.slice(0, this.limit);
  }

  public nextSlide() {
    this.swiper.nextSlide();
  }

  public prevSlide() {
    this.swiper.prevSlide();
  }
}
