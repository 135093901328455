import { Component, OnDestroy, OnInit } from '@angular/core';
import { TemplateDataService } from '../global/services/template-data.service';

@Component({
  selector: 'app-temp-portal-component',
  templateUrl: './temp-portal-component.component.html',
  styleUrls: ['./temp-portal-component.component.scss']
})
export class TempPortalComponentComponent implements OnInit, OnDestroy {

  constructor(
    private templateData: TemplateDataService,
  ) {
    this.templateData.setShowFooter(false);
   }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.templateData.setShowFooter(true);
  }

}
