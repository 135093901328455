import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID  } from '@angular/core';
import { CommonModule  } from '@angular/common';
import { AppComponent } from './app.component';
import { SearchModule } from './search/search/search.module';
import { UserModule } from './user/user/user.module';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './global/helpers/jwt.interceptor';
import { ErrorInterceptor } from './global/helpers/error.interceptor';
import { HammerConfig } from './global/classes/hammer-config';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { MatDialogModule } from '@angular/material/dialog';
import localeNb from '@angular/common/locales/nb';
import { registerLocaleData } from '@angular/common';
import { PrivacyTermsComponent } from './privacy-terms/privacy-terms.component';
import { ReportAbuseComponent } from './global/comp/report-abuse/report-abuse.component';
import { SessionHistoryService } from './global/services/session-history.service';
import { SupportFormComponent } from './global/comp/support-form/support-form.component';
import { SessionLogInterceptor } from './global/helpers/session-log.interceptor';
import { BrowserRegistrationComponent } from './browser-registration/browser-registration.component';
import { BrowserMessageComponent } from './browser-message/browser-message.component';
import { TempPortalComponentComponent } from './temp-portal-component/temp-portal-component.component';
import { FooterModule } from './global/comp/footer/footer.module';




registerLocaleData(localeNb);

const routes: Routes = [
  { path: 'rapporter/svindel-epost', component: ReportAbuseComponent },
  { path: 'personvern', component: PrivacyTermsComponent },
  { path: 'support', component: SupportFormComponent },
  { path: 'browser-registration', component: BrowserRegistrationComponent },
  { path: 'browser-message', component: BrowserMessageComponent },
  { path: 'temp-portal', component: TempPortalComponentComponent },

  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    PrivacyTermsComponent,
    ReportAbuseComponent,
    SupportFormComponent,
    BrowserRegistrationComponent,
    BrowserMessageComponent,
    TempPortalComponentComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SearchModule,
    UserModule,
    RouterModule.forRoot( routes, {
      enableTracing: true,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    }),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    Angulartics2Module.forRoot(),
    MatDialogModule,
    CommonModule, 
    FooterModule,
  ],
  providers: [
    
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SessionLogInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'nb' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(sessionHistory: SessionHistoryService) {
    sessionHistory.init();
  }
}

if( environment['nukelog'] ) {
  window.console.log = function(){};
}
