import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';  // Import MatInputModule for Material input components
import { InpSingleComponent } from 'src/app/search/comp/input/inp-single/inp-single.component';  // Import shared component


@NgModule({
    
    declarations: [InpSingleComponent],  // Declare component only here
    exports: [InpSingleComponent],       // Export to be used in other modules
    imports: [
                CommonModule,
                FormsModule,
                MatInputModule,

    ]              // Include necessary Angular modules
  })
  export class SearchSharedModule {}
