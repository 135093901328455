import { Component, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { SearchService, IsearchResult, IsearchEntry } from '../../../search/services/search.service';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { debounce, map } from 'rxjs/operators';
import { Search } from '../../../search/classes/search';
import { CurrSearchService } from '../../../search/services/curr-search.service';
import { AuthenticationService } from '../../../global/services/authentication.service';
import { FooterVisibilityService } from '../../../global/services/footer-visibility.service';
import { SearchStateService } from 'src/app/search/comp/frontpage/search-state.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input() resultEntries$: Observable<IsearchEntry[]>;
  private subscription: Subscription = new Subscription();
  dropdownOpen = false;
  dropdownOpen2 = false;
  isVisible: boolean = true;
  isLoggedIn: boolean = false;
  veteranYear: number;
  currentYear: number = new Date().getFullYear();
  search: Search;
  args: {} = {};
  opened = false;
  argsChanged$: Subscription;
  isFrontPage: boolean = false;
  currentSearchParams: any;

  private resultEntriesSubject = new BehaviorSubject<IsearchEntry[]>([]);
  hmaType: string;
  hasMoreResults: boolean;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private footerVisibilityService: FooterVisibilityService,
    private currSearchService: CurrSearchService,
    private searchService: SearchService,
    private searchStateService: SearchStateService,
    private cdr: ChangeDetectorRef
  ) {
    const currentYear = new Date().getFullYear();
    this.veteranYear = currentYear - 30;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Use urlAfterRedirects or event.url if you prefer
        const urlWithoutParams = event.urlAfterRedirects.split('?')[0];
        if (urlWithoutParams === '/') {
          this.isFrontPage = true;
        } else { 
          this.isFrontPage = false;
        }
      }
    });
  }

  get currentHmaType(): string | null {
    const cachedType = this.searchStateService.getLastSelectedType();
    //console.log('Footer currentHmaType getter, cached type:', cachedType);
    return cachedType;
  }

  toggleDropdown(event: Event): void {
    event.preventDefault();
    this.dropdownOpen = !this.dropdownOpen;
  }

  toggleDropdown2(event: Event): void {
    event.preventDefault();
    this.dropdownOpen2 = !this.dropdownOpen2;
  }

  closeDropdown(): void {
    this.dropdownOpen = false;
  }

  currentType: string | null = null;

  onSearchFooter(searchtext: string, isFrontPage?: boolean, ) {
    //let hmaType = this.hmaType;
    let hmaType = this.currentHmaType;
    //console.log('fixSearch...searchtextsearchtext' + searchtext + 'hmaType:' + hmaType);

    this.searchStateService.setPagesLoadedBatch(0);
    // If not on the front page, navigate to home and then execute the same logic
    if (!isFrontPage) {
        this.router.navigate(['/']).then(() => {
            this.executeSearchLogic(searchtext, hmaType);
        });
    } else {
        // If on the front page, execute the logic directly
        this.executeSearchLogic(searchtext);
    }
  
  }

  private executeSearchLogic(searchtext: string, hmaType?: string) {
    
      this.resultEntriesSubject.next([]); // clear old results
      this.searchStateService.setHasMoreResults(true); // set hasMoreResults to true
      this.searchStateService.clearPageParam(true);
      this.searchStateService.setStartFromPageZero(true);
      this.navigateFromSearch(hmaType, searchtext);
  }

  navigateFromSearch(hmaType: string, searchtext: string) {
    let queryParams: any;
  
    // If searchtext is already an object, use it directly;
    // otherwise, parse it.
    let parsedSearchText: any = {};
    if (typeof searchtext === 'string') {
      try {
        parsedSearchText = JSON.parse(searchtext);
      } catch (e) {
        console.error('Error parsing search text:', e);
      }
    } else {
      parsedSearchText = searchtext;
    }
  
    // Build queryParams based on hmaType, passing the t property
    if (hmaType === 'hmaAuto') { 
      queryParams = { type: 'hmaAuto', t: parsedSearchText.t };
    } else if (hmaType === 'hmaMobileHome') {
      queryParams = { type: 'hmaMobileHome', t: parsedSearchText.t };
    } else if (hmaType === 'hmaCamping') {
      queryParams = { type: 'hmaCamping', t: parsedSearchText.t };
    } else if (hmaType === 'hmaMC') {
      queryParams = { type: 'hmaMC', t: parsedSearchText.t };
    } else if (hmaType === 'hmaVeteran') {
      queryParams = { type: 'hmaAuto', yearmodel: '1900-1995' };
    } else if (hmaType === 'hmaBoat') {
      queryParams = { type: 'hmaBoat', t: parsedSearchText.t };
    } else {
      queryParams = { type: hmaType };
    }
  
    //console.log('navigateFromSearch: Setting queryParams:', JSON.stringify(queryParams));
  
    this.searchStateService.appendSearchParams(queryParams);
    this.searchStateService.updateResultEntries(false).subscribe(
      result => {
        if (result.data.length > 0) {
          const updatedEntries = [...this.resultEntriesSubject.value, ...result.data];
          this.resultEntriesSubject.next(updatedEntries);
          this.cdr.detectChanges();
        } else {
          //console.log('Footer: navigateFromButton: No more entries to load.');
        }
        this.searchStateService.updateUrl();
        this.cdr.detectChanges();
        this.searchStateService.scrollToID('ads', 'smooth');
      },
      error => {
        console.error('Error loading more entries:', error);
      }
    );
    
  }


  onHmaButtonClicked(hmaType: string, currentParams?: any,) {
    this.resultEntriesSubject.next([]); // clear old results
    this.searchStateService.setPagesLoadedBatch(0);
    this.searchStateService.setHasMoreResults(true); // set hasMoreResults to true
    this.searchStateService.setStartFromPageZero(true); // set hasMoreResults to true
    
    this.searchStateService.clearPageParam(true);

    const from = 'footer.component.ts: get onHmaButtonClicked';
    this.currentSearchParams = this.searchStateService.getSearchParams(from);

    this.searchStateService.setStartFromPageZero(true);
    this.navigateFromButton(hmaType);
  }

  navigateFromButton(type: string) {
    let queryParams: any
    this.searchStateService.clearPageParam();


    if (type === 'hmaVeteran') {
      queryParams = { type: 'hmaAuto', yearmodel: '1900-1995', };
    } else {
      queryParams = { type: type };
    }
    console.log('////queryPARAMS:' + JSON.stringify(queryParams));
    this.searchStateService.setNewSearchParams(queryParams);

    this.searchStateService.updateResultEntries(false).subscribe(
      result => {
        if (result.data.length > 0) {
          const updatedEntries = [...this.resultEntriesSubject.value, ...result.data];
          this.resultEntriesSubject.next(updatedEntries);
          this.cdr.detectChanges();
        } else {
          //console.log('Footer: navigateFromButton: No more entries to load.');
        }
        //this.searchStateService.updateUrl();
        this.searchStateService.scrollToID('ads', 'smooth');
      },
      error => {
        console.error('Error loading more entries:', error);
      }
    );
  }
  

  clearEntries() {
    this.resultEntriesSubject.next([]);
  }

  ngOnInit() {


    //this.currentSearchParams = this.searchStateService.getSearchParams();
    //const currentParams = this.currentSearchParams;

    this.authService.isLoggedIn().subscribe(
      (loggedIn) => {
        this.isLoggedIn = loggedIn;
      },
      (error) => {
        console.error('Error checking login status:', error);
      }
    );

    this.search = this.currSearchService.get();
    this.args = this.search.getAllArgs({});

    this.argsChanged$ = this.search.args.observeArgChanges().pipe(
      debounce(() => timer(1000))
    ).subscribe(() => {
      const queryParams = this.search.args.toParams();
    });

    this.footerVisibilityService.footerVisibility$.subscribe(
      (visible) => {
        this.isVisible = visible;
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
