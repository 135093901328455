import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MappingService {
    
    // Shared function to ensure the parameter is an array
    ensureArray(param, paramName) {
        if (typeof param === 'string') {
        return param.split(',').map(item => item.trim());
        } else if (Array.isArray(param)) {
        return param;
        } else {
        console.warn(`${paramName} is not a string or array:`, param);
        return [];
        }
    }

    // Shared function to map and filter conditions
    mapMultiValChildValues(conditions, mapping) {
        return conditions.map(condition => {
        //console.log('Processing condition:', condition);
        return mapping[condition] || null;
        }).filter(condition => condition !== null);
    }

    // Function to wash params
    washParams(params) {
        //console.log('lazyLoadFix: mappingService.washParams - Input params:', JSON.stringify(params));
        this.processParam(params, 'saletype', this.saletypeMapping);
        this.processParam(params, 'condition', this.conditionMapping);
        this.removeEmptyParams(params);
        //console.log('lazyLoadFix: mappingService.washParams - Output params:', JSON.stringify(params));
    }

    removeEmptyParams(params: any) {
        Object.keys(params).forEach(key => {
            if (key !== 'page' && (params[key] === null || params[key] === undefined || params[key] === '')) {
                delete params[key];
            }
        });
    }

    
    // Generic function to process a parameter
    private processParam(params, paramName, mapping) {
    if (params[paramName]) {
        const values = this.ensureArray(params[paramName], `params.${paramName}`);
        const mappedValues = this.mapMultiValChildValues(values, mapping);

        if (mappedValues.length > 0) {
        params[paramName] = mappedValues.join(',');
        } else {
        delete params[paramName];
        }
    } else {
        //console.log(`params.${paramName} is undefined or null`);
        }
    }     
    // Mapping for saletype
    saletypeMapping = {
    'Til salgs': 'forsale',
    'Kan byttes': 'swappable',
    'Leasing': 'leasing'
    };

    // Mapping for condition
    conditionMapping = {
    'Garanti': 'warranty',
    'Tilstandsrapport': 'qualityrep',
    'Servicehistorikk': 'service',
    'Nylig kontroll (EU/NAF)': 'recentcheck',
    'warranty': 'warranty',
    'qualityrep': 'qualityrep',
    'service': 'service',
    'recentcheck': 'recentcheck'
    };


}
