import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SearchViewMappingService {

  constructor() { }

  formatSearchParams(params: any): { key: string, value: string }[] {
    const formattedParams = [];
    if (params.type) {
      formattedParams.push({ key: 'type', value: `Kjøretøytype: ${this.mapHmaTypes(params.type)}` });
    }
    if (params.t) {
      formattedParams.push({ key: 't', value: `Søketekst: ${params.t}` });
    }
    if (params.bedTypes) {
      formattedParams.push({ key: 'bedTypes', value: `Sengeløsninger: ${params.bedTypes}` });
    }
    if (params.heatingSystem) {
      formattedParams.push({ key: 'heatingSystem', value: `Varmesystem: ${params.heatingSystem}` });
    }
    if (params.chassisManufacturer) {
      formattedParams.push({ key: 'chassisManufacturer', value: `Chassis-fabrikant: ${params.chassisManufacturer}` });
    }
    if (params.condition) {
      formattedParams.push({ key: 'condition', value: `Tilstand: ${this.mapConditionTypes(params.condition)}` });
    }
    if (params.b){
      formattedParams.push({ key: 'b', value: `Merke og modell: ${this.formatParentAndChild(params.b)}` });
    }
    if (params.saletype){
      formattedParams.push({ key: 'saletype', value: `Salgsform: ${this.mapSaleTypes(params.saletype)}` });
    }
    if (params.fueltype){
        formattedParams.push({ key: 'fueltype', value: `Drivstoff: ${params.fueltype}` });
    }
    if (params.fylke){
        formattedParams.push({ key: 'fylke', value: `Sted: ${this.formatParentAndChild(params.fylke)}` });
    }
    if (params.sellertype){
        formattedParams.push({ key: 'sellertype', value: `Annonsør: ${params.sellertype}` });
    }
    if (params.chassis){
        formattedParams.push({ key: 'chassis', value: `Karosseri: ${params.chassis}` });
    }
    if (params.colour){
        formattedParams.push({ key: 'colour', value: `Farge: ${params.colour}` });
    }
    if (params.equipment){
        formattedParams.push({ key: 'equipment', value: `Utstyr: ${params.equipment}` });
    }
    if (params.wheeldrive){
        formattedParams.push({ key: 'wheeldrive', value: `Hjuldrift: ${params.wheeldrive}` });
    }
    if (params.transmission){
        formattedParams.push({ key: 'transmission', value: `Girkasse: ${params.transmission}` });
    }
    if (params.category){
        if (params.type === 'hmaMC') {
            formattedParams.push({ key: 'category', value: `Type: ${this.formatParentAndChild(params.category)}` });
        } else {
            formattedParams.push({ key: 'category', value: `Type: ${params.category}` });
        }
    }
    if (params.price){
        formattedParams.push({ key: 'price', value: `Pris: ${this.formatMinMax(params.price, true)} kr` });
    }
    if (params.yearmodel){
        formattedParams.push({ key: 'yearmodel', value: `Årsmodell: ${this.formatMinMax(params.yearmodel)}` });
    }
    if (params.usageTime){
        formattedParams.push({ key: 'usageTime', value: `Brukstid: ${this.formatMinMax(params.usageTime, true)} timer` });
    }
    if (params.km){
        formattedParams.push({ key: 'km', value: `Kilometerstand: ${this.formatMinMax(params.km, true)} km` });
    }
    if (params.range){
        formattedParams.push({ key: 'range', value: `Rekkevidde: ${this.formatMinMax(params.range)} km` });
    }
    if (params.hk){
        formattedParams.push({ key: 'hk', value: `Hestekrefter: ${this.formatMinMax(params.hk)} hk` });
    }
    if (params.numseats){
        formattedParams.push({ key: 'numseats', value: `Seter: ${this.formatMinMax(params.numseats)} seter` });
    }
    if (params.trailerweight){
        formattedParams.push({ key: 'trailerweight', value: `Maks tilhengervekt: ${this.formatMinMax(params.trailerweight)} kg` });
    }
    if (params.numsleepers){
        formattedParams.push({ key: 'numsleepers', value: `Antall: ${this.formatMinMax(params.numsleepers)} soveplasser` });
    }
    if (params.length){
        if (params.type === 'hmaBoat') {
            formattedParams.push({ key: 'length', value: `Lengde: ${this.formatMinMax(params.length)} fot` }); // Feet if boat
        } else {
            formattedParams.push({ key: 'length', value: `Lengde: ${this.formatMinMax(params.length)} cm` }); // CM else
        }
    }
    if (params.width){
        formattedParams.push({ key: 'width', value: `Bredde: ${this.formatMinMax(params.width)} cm` });
    }
    if (params.weight){
        formattedParams.push({ key: 'weight', value: `Totalvekt: ${this.formatMinMax(params.weight, true)} kg` });
    }
    if (params.ccm){
        formattedParams.push({ key: 'ccm', value: `Sylindervolum: ${this.formatMinMax(params.ccm)} cc` });
    }
    if (params.boatType){
        formattedParams.push({ key: 'boatType', value: `Type: ${params.boatType}` });
    }
    if (params.boatMaterial){
        formattedParams.push({ key: 'boatMaterial', value: `Byggemateriale: ${params.boatMaterial}` });
    }
    if (params.motorType){
        formattedParams.push({ key: 'motorType', value: `Motortype: ${params.motorType}` });
    }
    return formattedParams;
  }

  formatMinMax(range, addThousandSeparator = false) {
    // Split the range into min and max values
    const [min, max] = range.split('-');

    // Helper function to add thousand separators
    const addSeparator = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    // Format min and max values
    const formattedMin = addThousandSeparator ? addSeparator(min) : min;
    const formattedMax = addThousandSeparator ? addSeparator(max) : max;

    // Handle cases where only min or max is provided
    if (min && !max) {
        return `minimum ${formattedMin}`;
    } else if (!min && max) {
        return `maksimalt ${formattedMax}`;
    } else if (min && max) {
        return `${formattedMin} - ${formattedMax}`;
    } else {
        return ''; // If neither min nor max is provided
    }
}

  formatParentAndChild(input) {
    const parentChildGroups = input.split(',');
    const formattedGroups = parentChildGroups.map(group => {
      const parts = group.split('_');
      const parent = parts[0];
      const children = parts.slice(1).join(', ');
      if (parent && children) {
        return `${parent}: ${children}`;
      } else {
        return `${parent}`;
      }
      
    });
    return formattedGroups.join(' | ');
  }



  mapHmaTypes(type: string): string {
    const categoryMap: { [key: string]: string } = {
      hmaAuto: 'bil',
      hmaMobileHome: 'bobil',
      hmaMC: 'MC',
      hmaCamping: 'campingvogn',
      hmaBoat: 'båt'
    };
    return categoryMap[type] || type;
  }


  mapConditionTypes(types: string): string {
    const categoryMap: { [key: string]: string } = {
      warranty: 'garanti',
      qualityrep: 'tilstandsrapport',
      service: 'servicehistorikk',
      recentcheck: 'nylig kontroll (EU/Naf)'
    };
    const conditionList = types.split(',').map(type => type.trim());
    const mappedConditions = conditionList.map(type => categoryMap[type] || type);
    return mappedConditions.join(', ');
  }

  mapSaleTypes(types: string): string {
    const categoryMap: { [key: string]: string } = {
      forsale: 'til salgs',
      leasing: 'leasing',
      swappable: 'kan byttes'
    };
    const conditionList = types.split(',').map(type => type.trim());
    const mappedConditions = conditionList.map(type => categoryMap[type] || type);
    return mappedConditions.join(', ');
  }

  /*
  mapFuelTypes(types: string): string {
    const categoryMap: { [key: string]: string } = {
      Bensin: 'Bensin',
      DIe: 'leasing',
      swappable: 'kan byttes'
    };
    const conditionList = types.split(',').map(type => type.trim());
    const mappedConditions = conditionList.map(type => categoryMap[type] || type);
    return mappedConditions.join(', ');
  }
  */
}
