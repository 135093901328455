import { Component, OnInit } from '@angular/core';
import { TemplateDataService } from './global/services/template-data.service';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { MetaTagsService } from './global/services/metatags.service';
import { Router, NavigationEnd } from '@angular/router';
import { SearchFiltersService } from 'src/app/search/comp/search-filters/search-filters.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  //private adSlotsInitialized: Record<string, boolean> = {};

  constructor(
    public templateData: TemplateDataService,
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private metatags: MetaTagsService,
    private router: Router,
    private searchFiltersService: SearchFiltersService,
  ) {
    // Start tracking with Angulartics2
    angulartics2GoogleAnalytics.startTracking();
  }

  ngOnInit(): void {
    document.addEventListener('click', () => {
      this.searchFiltersService.outsideClick.emit();
    });
  }
}
