import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ProspectEditSession } from './prospect-edit.service';
import { numberValidator } from '../../global/validators/number.validator';
import { editTypes } from '../../global/static/edit-types';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { HmaTypeService } from 'src/app/global/services/hma-type.service';

@Injectable({
  providedIn: 'root'
})
export class EditFormService {
  form: FormGroup;
  data: any;
  session: ProspectEditSession = new ProspectEditSession;
  fieldOptions;

  constructor(private formBuilder: FormBuilder, private http: HttpClient, private hmaTypes: HmaTypeService) { }

  setSession(session: ProspectEditSession) {
    this.session = session;
  }

  /**
   * Generate the FormGroup for the edit form, with the forms and fields that are
   * active for the current hmaType.
   */
  setupForms() {
    const data = this.session.data;
    const typeConfig = this.hmaTypes.getType(this.session.type);

    const forms = {
      regNo: {
        regNo: [data.regNo]
        //, regNoHide: [data.regNoHide]
      },

      mainAd: {
        brand: [data.brand, [Validators.required]],
        model: [data.model],
        variant: [data.variant],
        motorBrand: [data.motorBrand],
        yearmodel: [data.yearmodel, [Validators.required, Validators.pattern(/^[0-9]{4}$/)]],
        usageTime: [data.usageTime, [Validators.required, Validators.pattern(/^[0-9]{4}$/)]],
        regYear: [this.numberDefault('regYear'), [Validators.pattern(/(^$|^[0-9]{4}$)/)]],
        mileage: [this.numberDefault('mileage'), [Validators.required, numberValidator(false)]],
        numOwners: [this.numberDefault('numOwners'), [numberValidator(false)]],
        chassis: [data.chassis, [Validators.required]],
        chassisType: [data.chassisType],
        category: [this.session.type == 'hmaMC' && !data.category ? 'MC' : data.category, [Validators.required]],
        subCategory: [data.subCategory, []],
        regClass: [data.regClass, [Validators.required]],
        numSeats: [this.numberDefault('numSeats'), [Validators.required, numberValidator(false)]],
        numSleepers: [this.numberDefault('numSleepers'), [Validators.required, numberValidator(false)]],
        numDoors: [this.numberDefault('numDoors'), [numberValidator(false)]],
        trunk1Ltr: [this.numberDefault('trunk1Ltr'), [numberValidator(false)]],
        weight: [this.numberDefault('weight'), [numberValidator(false)]],
        length: [this.numberDefault('length'), [numberValidator(false)]],
        width: [this.numberDefault('width'), [numberValidator(false)]],
        colour: [data.colour, [Validators.required]],
        colourDesc: [data.colourDesc],
        colorInterior: [data.colorInterior],
        bedTypes: [data.bedTypes ? data.bedTypes.split(', ') : []],
        heatingSystem: [data.heatingSystem],
        chassisManufacturer: [data.chassisManufacturer],
        fueltype: [data.fueltype, [Validators.required]],
        engineSize: [this.numberDefault('engineSize'), [numberValidator(true)]],
        engineCCM: [this.numberDefault('engineSize'), [numberValidator(true)]],
        enginePower: [this.numberDefault('enginePower'), [numberValidator(false)]],
        co2gkm: [this.numberDefault('co2gkm'), [numberValidator(false)]],
        fuelConsumptionAverage: [this.numberDefault('fuelConsumptionAverage'), [numberValidator(true)]],
        elBatteryRange: [this.numberDefault('elBatteryRange'), [numberValidator(false)]],
        transmission: [data.transmission, [Validators.required]],
        transmissionDesc: [data.transmissionDesc],
        wheeldrive: [data.wheeldrive, [Validators.required]],
        wheeldriveDesc: [data.wheeldriveDesc],
        equipment: [data.equipment || []],
      },

      description: {
        title: [data.title, [Validators.required, Validators.maxLength(150)]],
        freetext: [data.freetext, [Validators.required]],
        videoUrl: [data.videoUrl],
      },

      report: {
        hasHistory: [data.hasHistory],
        euControlDate: [data.euControlDate],
        nafControlDate: [data.nafControlDate],
        hasWarranty: [data.hasWarranty],

        hasQualityReport: [data.hasQualityReport],
        isNew: [data.isNew],
        usedImported: [data.usedImported],
        warrantyType: [data.warrantyType],
        warrantyText: [data.warrantyText],
        hasKnownProblems: [data.hasKnownProblems],
        knownProblems: [data.knownProblems],
        serviced: [data.serviced],
        hasServiceDescription: [data.hasServiceDescription],
        hasDamage: [data.hasDamage],
        hasDamageDesc: [data.hasDamageDesc],
        timingBeltChanged: [data.timingBeltChanged],
        recentlyRepaired: [data.recentlyRepaired],
        recentlyRepairedDesc: [data.recentlyRepairedDesc],
        tyresState: [data.tyresState],
        hasNoLiens: [data.hasNoLiens],
        hasRust: [data.hasRust],
        hasRustDesc: [data.hasRustDesc],
        hasTwoKeys: [data.hasTwoKeys],
        hasTwoRimSets: [data.hasTwoRimSets],
        qualityReport: [data.qualityReport],
      },

      sale: {
        cname: [data.cname],
        caddr: [data.caddr, [Validators.maxLength(150)]],
        hideAddr: [data.hideAddr],
        cpostno: [data.cpostno, [Validators.required, Validators.pattern(/[0-9]{4}/)]],
        cplace: [{ disabled: true, value: data.cplace }],
        cmobile: [data.cmobile, [Validators.pattern(/[0-9]{8}/)]],
        swap: [data.swap],
        swapDesc: [data.swapDesc],
        priceEx: [data.priceEx == 0 ? null : data.priceEx, [numberValidator(false), Validators.min(typeConfig.minPrice)]],
        regPrice: [data.regPrice, [numberValidator(false)]],
        regPriceExcem: [data.regPriceExcem],
        xlBilExport: [data.xlBilExport]
      },
    };

    let formGroups = {};

    Object.keys(forms).forEach((formName) => {
      if (FormConfig[formName].enableFor.indexOf(this.session.type) >= 0) {
        let fields = {};

        Object.keys(forms[formName]).forEach((fieldName) => {
          if (FieldConfig[fieldName].enableFor.indexOf(this.session.type) >= 0) {
            const fieldData = forms[formName][fieldName];

            if (FieldConfig[fieldName].enableFor && !this.isFieldRequired(fieldName, this.session.type) && fieldData[1]) {
              fieldData[1] = fieldData[1].filter(validator => validator !== Validators.required);
            }

            fields[fieldName] = fieldData;
          }
        });

        formGroups[formName] = this.formBuilder.group(fields);
      }

    });

    this.form = this.formBuilder.group(formGroups);
  }

  /**
   * Get the edit form.
   * @return FormGroup
   */
  getForm(): FormGroup {
    this.setupForms();
    return this.form;
  }

  /**
   * Get a specific field in the form.
   * @param fieldName
   */
  getFormField(fieldName: string): FormControl {
    let field;

    Object.keys(this.form.controls).some((formName) => {
      field = this.form.controls[formName].get(fieldName);
      return field;
    });

    return field;
  }

  /**
   * Give a default value for a numeric input. Empty string if 0.
   *
   * @param name Name of the input.
   */
  private numberDefault(name: string) {
    return this.session.data[name] != '0' ? this.session.data[name] : '';
  }

  /**
   * Check if a field is active for a given type.
   *
   * @param fieldName
   * @param hmaType
   */
  isFieldActive(fieldName: string, hmaType: string) {
    return FieldConfig[fieldName] && FieldConfig[fieldName].enableFor.indexOf(hmaType) >= 0;
  }

  /**
   * Check if the given field is required for a given type. Only works if
   * the "requiredFor" config is set for the field, otherwise defaults to false.
   *
   * @param fieldName
   */
  isFieldRequired(fieldName: string, hmaType: string) {
    return FieldConfig[fieldName] && FieldConfig[fieldName].requiredFor && FieldConfig[fieldName].requiredFor.indexOf(hmaType) >= 0;
  }

  /**
   * Check if a form is active for a given type.
   *
   * @param fieldName
   * @param hmaType
   */
  isFormActive(formName: string, hmaType: string) {
    return FormConfig[formName] && FormConfig[formName].enableFor.indexOf(hmaType) >= 0;
  }

  /**
   * Get the label for a field, based on the hmaType.
   */
  getFieldLabel(fieldName: string, hmaType: string) {
    const fieldConfig = FieldConfig[fieldName];
    let label = fieldConfig.label;

    if (fieldConfig.overrides && fieldConfig.overrides[hmaType] && fieldConfig.overrides[hmaType].label) {
      label = fieldConfig.overrides[hmaType].label;
    }

    return label;
  }

  /**
   * Get the label for a form, based on the hmaType.
   */
  getFormLabel(formName: string, hmaType: string) {
    const formConfig = FormConfig[formName];
    let label = formConfig.label;

    if (formConfig.overrides && formConfig.overrides[hmaType] && formConfig.overrides[hmaType].label) {
      label = formConfig.overrides[hmaType].label;
    }

    return label;
  }

  /**
   * Get all the options for select fields in the edit form.
   */
  getAllFieldOptions(forceReset: boolean = false) {
    if (this.fieldOptions !== undefined && !forceReset) {
      return this.fieldOptions;
    }

    this.fieldOptions = {};

    for (const fieldName of Object.keys(editTypes)) {
      this.fieldOptions[fieldName] = this.getFieldOptions(fieldName);

      // For fields that depend on another field, listen for changes
      if (FieldConfig[fieldName]['dependsOn'] && this.getFormField(FieldConfig[fieldName]['dependsOn'])) {
        this.getFormField(FieldConfig[fieldName]['dependsOn']).valueChanges.subscribe(
          (value) => { this.fieldOptions[fieldName] = this.getFieldOptions(fieldName); }
        );
      }
    }

    this.getBedTypeOptions().subscribe(
      (values) => this.fieldOptions['bedTypes'] = values
    );

    this.getEquipmentOptions(this.session.type).subscribe(
      (values) => this.fieldOptions['equipment'] = values
    );

    return this.fieldOptions;
  }

  /**
   * Get field options for a specific field.
   */
  getFieldOptions(fieldName: string) {
    let configOptions = editTypes[fieldName][this.session.type] !== undefined ? editTypes[fieldName][this.session.type] : editTypes[fieldName]['default'];
    let options = [];

    // Special handling for fields that depend on another field value
    if (configOptions && FieldConfig[fieldName]['dependsOn'] && this.getFormField(FieldConfig[fieldName]['dependsOn'])) {
      configOptions = configOptions[this.getFormField(FieldConfig[fieldName]['dependsOn']).value];
    }

    if (configOptions) {
      for (const i of Object.keys(configOptions)) {
        if (configOptions[i].id !== undefined) {
          options.push(configOptions[i]);
        }
        else {
          options.push({
            id: configOptions[i],
            name: configOptions[i],
          });
        }
      }
    }

    return options;
  }

  /**
   * Get options for the equipment select.
   *
   * @param hmaType
   */
  getEquipmentOptions(hmaType: string): Observable<any[]> {
    return <Observable<any[]>>this.http.get(`${environment.apiUrl}/a/resources/equipment`, { params: { t: hmaType }}).pipe(
      map((values: any[]) => {
        if (values.length > 0 && values[0].Id && !values[0].id) {
          let options = [];
          values.forEach(value => {
            options.push({
              id: value.Id,
              category: value.Category,
              name: value.Name
            });
          });

          return options;
        }

        return values;
      })
    );
  }

  getBedTypeOptions(): Observable<{ name: string }[]> {
    const hardcodedOptions = [
      { name: 'Dobbeltseng' },
      { name: 'Twin-beds' },
      { name: 'Queen-bed' },
      { name: 'Fransk seng' },
      { name: 'Køyesenger' }
    ];
  
    return of(hardcodedOptions); // Returns an Observable of the predefined list
  }

  /**
   * Check if the field is a required field as part of the quality report.
   */
  isRequiredInReport(fieldName: string) {
    return FieldConfig[fieldName] && FieldConfig[fieldName].requiredInReport;
  }
}

export const FieldConfig = {
  regNo: {
    label: 'Registreringsnummer',
    enableFor: ['hmaAuto', 'hmaMC', 'hmaMobileHome', 'hmaCamping'],
  },
/*
  regNoHide: {
    label: 'Skjul registreringsnummer',
    enableFor: ['hmaAuto', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },
 */
  brand: {
    label: 'Merke',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  model: {
    label: 'Modell',
    enableFor: ['hmaAuto', 'hmaMC', 'hmaBoat', 'hmaMobileHome', 'hmaCamping']
  },

  variant: {
    label: 'Modellspesifikasjon',
    enableFor: ['hmaAuto', 'hmaMC', 'hmaMobileHome']
  },

  category: {
    label: 'Kategori',
    enableFor: ['hmaMC', 'hmaMobileHome', 'hmaOther'],
    overrides: {
      hmaMobileHome: { label: 'Type bobil' },
      hmaBoat: { label: 'Båttype' },
    },
  },

  boatType: {
    label: 'Båttype',
    enableFor: ['hmaBoat'],
  },

  subCategory: {
    label: 'Type',
    dependsOn: 'category',
    enableFor: ['hmaMC', 'hmaOther'],
  },

  motorBrand: {
    label: 'motorBrand',
    enableFor: ['hmaBoat']
  },

  yearmodel: {
    label: 'Årsmodell',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  regYear: {
    label: 'Registreringsår',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome']
  },

  mileage: {
    label: 'Km.stand',
    enableFor: ['hmaAuto', 'hmaMC', 'hmaMobileHome', 'hmaCamping'], // 'hmaBoat' removed until decision has been made 
    requiredFor: ['hmaAuto', 'hmaMC', 'hmaMobileHome'],
    overrides: {
      hmaBoat: { label: 'Brukstid' },
    },
  },

  numOwners: {
    label: 'Antall eiere',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  chassis: {
    label: 'Karosseri',
    enableFor: ['hmaAuto']
  },

  chassisType: {
    label: 'Chassis type',
    enableFor: ['hmaMobileHome']
  },

  regClass: {
    label: 'Avgiftsklasse',
    enableFor: ['hmaAuto']
  },

  numSeats: {
    label: 'Antall seter',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMobileHome']
  },

  numSleepers: {
    label: 'Antall soveplasser',
    enableFor: ['hmaMobileHome', 'hmaCamping', 'hmaBoat',]
  },

  numDoors: {
    label: 'Antall dører',
    enableFor: ['hmaAuto']
  },

  weight: {
    label: 'Vekt',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  length: {
    label: 'Lengde',
    enableFor: ['hmaMobileHome', 'hmaBoat', 'hmaCamping']
  },

  width: {
    label: 'Bredde',
    enableFor: ['hmaMobileHome', 'hmaBoat', 'hmaCamping']
  },

  trunk1Ltr: {
    label: 'Bagasjeromsvolum',
    enableFor: ['hmaAuto', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  colour: {
    label: 'Farge',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome']
  },

  colourDesc: {
    label: 'Fargebeskrivelse',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome']
  },

  colorInterior: {
    label: 'Interiørfarge',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome']
  },

  fueltype: {
    label: 'Drivstoff',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMobileHome', 'hmaMC'],
  },

  boatMaterial: {
    label: 'Materiale',
    enableFor: ['hmaBoat'],
  },

  motorIncluded: {
    label: 'Motor inkludert',
    enableFor: ['hmaBoat'],
  },

  motorType: {
    label: 'Motortype',
    enableFor: ['hmaBoat'],
  },

  usageTime: {
    label: 'Brukstid motor',
    enableFor: ['hmaBoat'],
  },

  engineSize: {
    label: 'Sylindervolum',
    enableFor: ['hmaAuto', 'hmaMobileHome']
  },

  bedTypes: {
    label: 'Sengeløsninger',
    enableFor: ['hmaMobileHome']
  },

  heatingSystem: {
    label: 'Varmesystem',
    enableFor: ['hmaMobileHome']
  },

  chassisManufacturer: {
    label: 'Chassis-fabrikant',
    enableFor: ['hmaMobileHome']
  },

  engineCCM: {
    label: 'Sylindervolum',
    enableFor: ['hmaMC']
  },

  enginePower: {
    label: 'Hestekrefter',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome']
  },

  co2gkm: {
    label: 'CO2-utslipp',
    enableFor: ['hmaAuto', 'hmaMobileHome']
  },

  fuelConsumptionAverage: {
    label: 'Gjennomsnitt forbruk',
    enableFor: ['hmaAuto', 'hmaMobileHome']
  },

  elBatteryRange: {
    label: 'Rekkevidde',
    enableFor: ['hmaAuto']
  },

  transmission: {
    label: 'Girkasse',
    enableFor: ['hmaAuto', 'hmaMobileHome']
  },

  transmissionDesc: {
    label: 'Girkassebeskrivelse',
    enableFor: ['hmaAuto', 'hmaMobileHome']
  },

  wheeldrive: {
    label: 'Hjuldrift',
    enableFor: ['hmaAuto', 'hmaMobileHome']
  },

  wheeldriveDesc: {
    label: 'Hjuldriftbeskrivelse',
    enableFor: ['hmaAuto']
  },

  equipment: {
    label: 'Utstyr',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  title: {
    label: 'Tittel',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  freetext: {
    label: 'Beskrivelse (fritekst)',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  videoUrl: {
    label: 'Video',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  hasWarranty: {
    label: 'Har garanti',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  hasHistory: {
    label: 'Har fullstendig eierhistorikk',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  euControlDate: {
    label: 'Siste EU-kontroll',
    enableFor: ['hmaAuto', 'hmaMobileHome']
  },

  nafControlDate: {
    label: 'Siste NAF-kontroll',
    enableFor: ['hmaAuto']
  },

  // Quality report
  hasQualityReport: {
    label: 'Kjente feil og mangler',
    enableFor: ['hmaAuto']
  },

  isNew: { enableFor: ['hmaAuto'], requiredInReport: true },
  usedImported: { enableFor: ['hmaAuto'], requiredInReport: true },
  warrantyType: { enableFor: ['hmaAuto'], requiredInReport: true },
  warrantyText: { enableFor: ['hmaAuto'] },
  hasKnownProblems: { enableFor: ['hmaAuto'], requiredInReport: true },
  knownProblems: { enableFor: ['hmaAuto'] },
  serviced: { enableFor: ['hmaAuto'], requiredInReport: true },
  hasServiceDescription: { enableFor: ['hmaAuto'] },
  hasDamage: { enableFor: ['hmaAuto'], requiredInReport: true },
  hasDamageDesc: { enableFor: ['hmaAuto'] },
  timingBeltChanged: { enableFor: ['hmaAuto'], requiredInReport: true },
  recentlyRepaired: { enableFor: ['hmaAuto'], requiredInReport: true },
  recentlyRepairedDesc: { enableFor: ['hmaAuto'] },
  tyresState: { enableFor: ['hmaAuto'], requiredInReport: true },
  hasNoLiens: { enableFor: ['hmaAuto'], requiredInReport: true },
  hasRust: { enableFor: ['hmaAuto'], requiredInReport: true },
  hasRustDesc: { enableFor: ['hmaAuto'] },
  hasTwoKeys: { enableFor: ['hmaAuto'], requiredInReport: true },
  hasTwoRimSets: { enableFor: ['hmaAuto'], requiredInReport: true },
  qualityReport: { enableFor: ['hmaAuto'] },

  // Sale
  cname: {
    label: 'Selgers navn',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  caddr: {
    label: 'Adresse',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  hideAddr: {
    label: 'Skjul gate/vei i annonsen',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping'],
  },

  cpostno: {
    label: 'Postnummer',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  cplace: {
    label: 'Poststed',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  cmobile: {
    label: 'Mobil',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  swap: {
    label: 'Ønsker å bytte i/med',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  swapDesc: {
    label: 'Innbyttebeskrivelse',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping']
  },

  priceEx: {
    label: 'Pris',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping'],
    overrides: {
      hmaAuto: { label: 'Pris, uten omregistrering' }
    }
  },

  regPrice: {
    label: 'Omregistreringsavgift',
    enableFor: ['hmaAuto']
  },

  regPriceExcem: {
    label: 'Fritatt for omregistrering',
    enableFor: ['hmaAuto']
  },

  xlBilExport: {
    label: 'Sende til XLBil',
    enableFor: ['hmaAuto', 'hmaMobileHome']
  }
};

export const FormConfig = {
  regNo: {
    label: 'Registreringsnummer',
    enableFor: ['hmaAuto', 'hmaMC', 'hmaMobileHome', 'hmaCamping'],
  },
  mainAd: {
    label: 'Tekniske detaljer',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping'],
  },
  description: {
    label: 'Beskrivelse og bilder',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping'],
  },
  report: {
    label: 'Tilstand',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping'],
  },
  sale: {
    label: 'Pris og info',
    enableFor: ['hmaAuto', 'hmaBoat', 'hmaMC', 'hmaMobileHome', 'hmaCamping'],
  },
}
