import { Component, OnInit, OnDestroy, Renderer2, Input, ElementRef, ChangeDetectorRef, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { IsearchEntry, IsearchResult, SearchService } from '../../services/search.service';
import { Search } from '../../classes/search';
import { CurrSearchService } from '../../services/curr-search.service';
import { SearchArg } from '../../classes/search-args';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { TemplateDataService } from '../../../global/services/template-data.service';
import { WindowSizeService } from '../../../global/services/window-size.service';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { merge, filter, map, debounce, switchMap, skip, take } from 'rxjs/operators';
import { of } from 'rxjs/index';
import { Angulartics2 } from 'angulartics2';
import { MetaTagsService } from 'src/app/global/services/metatags.service';
import { HmaType, HmaTypeService } from 'src/app/global/services/hma-type.service';
import { KeyInfoMobileHomeComponent } from '../view-prospect/key-info/key-info-mobile-home/key-info-mobile-home.component';
import { SearchStateService } from 'src/app/search/comp/frontpage/search-state.service';
import { SearchViewMappingService } from './search-view-mapping.service';
import { SearchFiltersService } from '../search-filters/search-filters.service';
declare var googletag: any;  // Ensure googletag is available

@Component({
  selector: 'app-search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.css']
})
export class SearchViewComponent implements OnInit, OnDestroy {
  lastScrollTop = 0;
  @Input() resultEntries$: Observable<IsearchEntry[]>;
  search: Search;
  args: { [key: string]: SearchArg } = {};
  listeners: Subscription[] = [];
  res$: Observable<IsearchEntry[]>;
  totalEntries$: Observable<number>;
  paramsIsFetched: boolean = false;
  hmaTypes: HmaType[];
  hmaForMeta: string;
  hmaForButton: string;
  public veteranYear: number;
  opened = false;
  adSlots: { id: string; path: string; size: any[] }[] = [];
  private routerSubscription: Subscription | undefined;
  private resultEntriesSubject = new BehaviorSubject<IsearchEntry[]>([]);
  

  currentSearchParams: { key: string, value: string }[] = [];

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private currSearchService: CurrSearchService,
    private templateDataService: TemplateDataService,
    private windowSize: WindowSizeService,
    private angulartics2: Angulartics2,
    private renderer: Renderer2,
    private el: ElementRef,
    private metatags: MetaTagsService,
    hmaTypeService: HmaTypeService,
    private cdr: ChangeDetectorRef,
    private searchStateService: SearchStateService,
    private searchViewMappingService: SearchViewMappingService,
    private searchFiltersService: SearchFiltersService,
  ) {
    this.templateDataService.setShowFooter(false);
    this.hmaTypes = hmaTypeService.getSearchEnabledTypes();
    const currentYear = new Date().getFullYear();
    this.veteranYear = currentYear - 30;

    this.resultEntries$ = this.searchStateService.resultEntries$;
    this.totalEntries$ = this.searchStateService.totalEntries$.pipe(
      map(entries => entries || 0) // Ensure that null or undefined is mapped to 0
    );
  }

  get screenSize() {
    return this.windowSize.getScreenSize();
  }

  get currentHmaType() {
    const from = 'search-view.component.ts: get currentHmaType';
    const params = this.searchStateService.getSearchParams(from);
    return params && params.type ? params.type : null;
  }

  get yearModel() {
    const from = 'search-view.component.ts: get yearModel';
    const params = this.searchStateService.getSearchParams(from);
    return params && params.yearmodel ? params.yearmodel : null;
  }

  get fixedParams() {
    if (this.args['forhandler'] && !this.args['forhandler'].isEmpty()) {
      return { forhandler: this.args['forhandler'].toStr() };
    }
    return null;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
    const stickyElement = this.el.nativeElement.querySelector('#filter-wrapper');
    const stickyElementOffset = stickyElement.offsetTop;

    if (currentScroll > this.lastScrollTop && currentScroll > stickyElementOffset) {
      // Scrolling down and past the element: close the filter
      this.renderer.setStyle(stickyElement, 'top', '-2000px');
      //this.searchFiltersService.closeFilters(); implement some other way?
    } else {
      // Scrolling up or before the element: open the filter
      this.renderer.setStyle(stickyElement, 'top', '0px');
    }

    this.lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
  }

  onSelectChange(event: any, selectElement: HTMLSelectElement) {
    
    //console.log('search-filters.component - onSelectChange');
    const selectedValue = selectElement.value;
    const from = 'search-view.component.ts: onSelectChange';

    let newParams: any = { };


    if (selectedValue === "hmaVeteran") {
      newParams['type'] = 'hmaAuto';
      newParams['yearmodel'] = '-1995';
    } else {
      newParams['type'] = selectedValue;
    }

    console.log('newParams before setting : ' + JSON.stringify(newParams));
    this.searchStateService.setNewSearchParams(newParams);
    this.searchStateService.setStartFromPageZero(true);    
    this.searchStateService.setPagesLoadedBatch(0);

    this.searchStateService.updateResultEntries(false).subscribe(
      result => {
          if (result.data.length > 0) {
              const updatedEntries = [...this.resultEntriesSubject.value, ...result.data];
              this.resultEntriesSubject.next(updatedEntries);
              this.cdr.detectChanges();
          }
          this.searchStateService.updateUrl();
          this.cdr.detectChanges();
          this.searchStateService.scrollToID('ads', 'instant');
      },
      error => {
          console.error('Error loading more entries:', error);
      }
    );
    this.searchStateService.replaceUrl(newParams);
  }

  onTextChange(event: { t: string }) {
    // Extract text from event
    let text = event.t !== undefined ? event.t : '';
    
    // Instead of deleting the field, update the shared search text
    // This ensures the input remains consistent across all instances
    this.searchStateService.updateSearchText(text);
  
    // Get the existing params
    const from = 'search-view.component.ts: onTextChange';
    let oldParams: any = this.searchStateService.getSearchParams(from);
    let newParams: any = { ...oldParams };
  
    // Update params
    newParams['t'] = text;
  
    this.searchStateService.appendSearchParams(newParams);
  
    // Update the results if needed (this logic may remain unchanged)
    this.searchStateService.updateResultEntries(false).subscribe(
      result => {
        if (result.data.length > 0) {
          const updatedEntries = [...this.resultEntriesSubject.value, ...result.data];
          this.resultEntriesSubject.next(updatedEntries);
          this.cdr.detectChanges();
        }
        this.searchStateService.updateUrl();
        this.cdr.detectChanges();
        this.searchStateService.scrollToID('ads', 'instant');
      },
      error => {
        console.error('Error loading more entries:', error);
      }
    );

  }

  ngOnInit() {
    this.searchStateService.searchParams$.subscribe(() => {
      this.updateSearchParams();
    });
    
    this.updateSearchParams();

    this.search = this.currSearchService.get();
    if (!this.search) {
      console.error('Search is not initialized properly');
      return;
    }

    this.searchStateService.searchParams$.subscribe((params) => {
      this.args = this.search.getAllArgs(params);
    });


  }

  resetSearch() {
    //console.log('search-filters.component.ts - resetSearch called');
    this.searchStateService.clearSearchParams(); 
    this.searchStateService.setHasMoreResults(true); // set hasMoreResults to true
  }

  searchParams: any = {};

  private updateSearchParams(): void {
    const from = "search-results.component.ts - updateSearchParams()";
    this.searchParams = this.searchStateService.getSearchParams(from);
    this.currentSearchParams = this.formatSearchParams(this.searchParams);
    this.paramsIsFetched = !!this.searchParams;
  }

  formatSearchParams(params: any): { key: string, value: string }[] {
    return this.searchViewMappingService.formatSearchParams(params);
  }

  removeParam(key: string): void {
    //console.log('removeParam. Key: ' + key);
    this.searchStateService.deleteField(key);
    this.searchStateService.setNewSearchParams(this.searchParams);
    this.updateSearchParams();
    this.searchStateService.updateResultEntries(false).subscribe();
    this.searchStateService.replaceUrl(this.searchParams);
    this.cdr.detectChanges();
    this.searchStateService.scrollToID('ads', 'instant');
  }
  
  ngAfterViewChecked(): void {
   //Void 
  }

  setupNavSearchListener(): Observable<IsearchEntry[]> {
    let listener = this.activatedRoute.queryParams.pipe(skip(1)).subscribe(
      (params) => {
        this.search.args.reset(params);
        this.res$ = this.search.doSearch().pipe(
          map((res: IsearchResult) => {
            //console.log('res.data:', res.data);
            return res.data;
          })
        );
        //console.log(this.res$);
      }
      
    );

    this.listeners.push(listener);

    return this.router.events.pipe(
      merge(of('initial-search')),
      filter((evt) => {
        return evt === 'initial-search';
      }),
      take(1),
      switchMap(() => {
        this.search.args.reset(this.activatedRoute.snapshot.queryParamMap['params']);
        return this.search.doSearch();
      }),
      map((res: IsearchResult) => {
        return res.data;
      })
    );
  }

  ngOnDestroy() {
    this.templateDataService.setShowFooter(true);
    this.metatags.clear();
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    this.listeners.forEach(listener => listener.unsubscribe());
  }
}
