import { Component, Input, OnInit, ViewChild, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { SearchStateService } from 'src/app/search/comp/frontpage/search-state.service';
import { SearchArgMinMax } from '../../../classes/search-args';

@Component({
  selector: 'app-inp-min-max',
  templateUrl: './inp-min-max.component.html',
  styleUrls: ['./inp-min-max.component.css']
})
export class InpMinMaxComponent implements OnInit {
  @Input() arg: SearchArgMinMax;
  @Input() label: string;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() minPrefix: string;
  @Input() maxPrefix: string;
  @Input() suffix: string;
  @Input() showInput = false;
  

  @Output() valueChange = new EventEmitter<{ min: number, max: number }>();

  model: number[];
  inputsLoaded = false;
  useInputs = false;

  @ViewChild('slider') slider: any;

  sliderConfig: any = {
    behaviour: 'drag',
    connect: true,
    start: [0, 100000],
    keyboard: true,
    step: 10000,
    range: {
      min: 0,
      max: 0
    }
  };

  constructor(
    private changeDetector: ChangeDetectorRef,
    private searchStateService: SearchStateService
  ) {
    this.model = [0, 0];
    
  }


  toggleShowInputs() {
    this.showInput = !this.showInput;

    if (this.showInput && !this.inputsLoaded) {
      this.inputsLoaded = true;
    }
  }

  ngOnInit() {
    this.model = [this.sliderConfig.range.min, this.sliderConfig.range.max];
    this.sliderConfig.start[0] = this.arg.min;
    this.sliderConfig.start[1] = this.arg.max;
    this.sliderConfig.range.min = this.min;
    this.sliderConfig.range.max = this.max;
    this.sliderConfig.step = this.step;

  

    if (this.arg.min != null) {
      this.model[0] = this.arg.min;
    }

    this.model[1] = this.arg.max != null ? this.arg.max : this.max;

    this.searchStateService.searchParams$.subscribe(params => {
      // If the parameter for this input is missing, clear its local selection:
      if (!params[this.arg.name]) {
        // Clear the selection on your nodes:
        this.arg.min = null;
        this.arg.max = null;
        }

        // Force change detection to update the UI:
        this.changeDetector.markForCheck();
      });

  }

  // Updates slider and search results
  onInputChange() {
    //console.log('inp-min-max.component.ts - onInputChange');
    //console.log('this.arg.min', this.arg.min);
    //console.log('this.arg.max', this.arg.max);
    this.model = [this.arg.min, this.arg.max];
    this.valueChange.emit({ min: this.arg.min, max: this.arg.max });
  }

  // Updates input values and search results
  onSliderChange() {
    if (this.model[0] <= this.min) {
      this.model[0] = 0;
    }

    if (this.model[1] >= this.max) {
      this.model[1] = 0;
    }

    this.arg.min = this.model[0];
    this.arg.max = this.model[1];
    this.valueChange.emit({ min: this.arg.min, max: this.arg.max });
  }

  minText() {
    let labelText = '';

    if ((!this.model[0] || this.model[0] <= this.min) && this.minPrefix) {
      labelText += this.minPrefix;
    }

    if (this.model[0] === 0) {
      labelText += this.min;
    } else {
      labelText += this.model[0];
    }

    return labelText;
  }

  maxText() {
    let labelText = '';

    if ((!this.model[1] || this.model[1] >= this.max) && this.maxPrefix) {
      labelText += this.maxPrefix;
    }

    if (this.model[1] === 0) {
      labelText += this.max;
    } else {
      labelText += this.model[1];
    }

    return labelText;
  }

  changeInputType() {
    this.useInputs = !this.useInputs;

    if (this.useInputs && this.model[1] === this.max) {
      this.model[1] = null;
    } else if (!this.useInputs && (this.model[1] == null || this.model[1] === 0)) {
      this.model[1] = this.max;
    }
  }
}
