import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IsearchEntry } from '../../services/search.service';
import { IOtherAd, OtheradsService } from '../../services/otherads.service';
import { WindowSizeService } from '../../../global/services/window-size.service';
import { Location } from '@angular/common';
import { ScrollPosService } from 'src/app/global/services/scroll-pos.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

declare var googletag: any;  // Ensure googletag is available

@Component({
  selector: 'app-result-entry',
  templateUrl: './result-entry.component.html',
  styleUrls: ['./result-entry.component.scss']
})
export class ResultEntryComponent implements OnInit, AfterViewInit {
  @Input() entry: IsearchEntry;
  @Input() prospects: Observable<IOtherAd[]>;
  @Input() index: number; // index of the entry
 //@Input() index: number = 1
  @ViewChild('variant') variant!: ElementRef;
  @ViewChild('brand') brand!: ElementRef;
  @ViewChild('model') model!: ElementRef;
  @ViewChild('sellerName') sellerName!: ElementRef;
  @ViewChild('wrapper') wrapper!: ElementRef;

  isSeparator(): boolean {
    return this.entry && this.entry.isSeparator === true;
  }

  truncatedText: string = '';
  truncatedBrand: string = '';
  truncatedModel: string = '';
  truncatedSellerName: string = '';
  visibleInView = false;
  showRelated = true;
  openBadges = false;

 

  private adSlots: { id: string; path: string; size: any[] }[] = [];
  private hasAdjusted = false;
  private screenSize: string;
  private currentType: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private windowSize: WindowSizeService,
    private scrollPosition: ScrollPosService,
    private cdr: ChangeDetectorRef
  ) {
    this.route.queryParams.subscribe(params => {
      this.showRelated = params.forhandler === undefined || params.forhandler === '';
    });
    this.screenSize = this.windowSize.getScreenSize();
    this.currentType = this.getInitialType();
  }

  ngOnInit() {
   
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.variant) this.truncateText(this.variant, 200, 'variant');
      if (this.sellerName) this.truncateText(this.sellerName, 195, 'sellerName');
      if (this.brand) this.truncateText(this.brand, 200, 'brand');
      if (this.model) this.truncateText(this.model, 150, 'model');
    }, 500);

  }

  truncateText(elementRef: ElementRef, maxWidth: number, type: 'variant' | 'sellerName' | 'brand' | 'model') {
    if (!elementRef) return;

    const element = elementRef.nativeElement;

    let originalText = '';

    if (type === 'variant') {
      originalText = (this.entry.variant !== undefined && this.entry.variant !== null) ? this.entry.variant : '';
    } else if (type === 'sellerName') {
      originalText = (this.entry.sellerName !== undefined && this.entry.sellerName !== null) ? this.entry.sellerName : '';
    } else if (type === 'brand') {
      originalText = (this.entry.brand !== undefined && this.entry.brand !== null) ? this.entry.brand : '';
    } else if (type === 'model') {
      originalText = this.entry.model;
      originalText = (this.entry.model !== undefined && this.entry.model !== null) ? this.entry.model : '';
    }

    let tempText = originalText;
    element.innerText = originalText;

    while (element.scrollWidth > maxWidth && tempText.length > 0) {
      tempText = tempText.slice(0, -1);
      element.innerText = tempText + '...';
    }

    if (type === 'variant') {
      this.truncatedText = tempText + (tempText.length < originalText.length ? '...' : '');
    } else if (type === 'sellerName') {
      this.truncatedSellerName = tempText + (tempText.length < originalText.length ? '...' : '');
    } else if (type === 'brand') {
      this.truncatedBrand = tempText + (tempText.length < originalText.length ? '...' : '');
    } else if (type === 'model') {
      this.truncatedModel = tempText + (tempText.length < originalText.length ? '...' : '');
    }
  }

  /*
  setVisible({ target, visible }: { target: Element; visible: boolean }) {
    this.visibleInView = visible;
    if (visible) {
      this.loadAds();
    }
  }
  */
  toggleBadgesDesktop(open) {
    if (this.windowSize.checkScreenSize('mobile')) {
      return;
    }

    this.openBadges = open;
  }

  toggleBadgesMobile(event) {
    if (this.windowSize.checkScreenSize('mobile')) {
      event.stopPropagation();
      event.preventDefault();
      this.openBadges = !this.openBadges;
    }
  }

  onEntryClick(event) {
    this.scrollPosition.storeScroll('entry-' + this.entry.aditemid);
  }

  getInitialType(): string {
    const currentParams = this.route.snapshot.queryParams;
    if (currentParams && currentParams.type) {
      return currentParams.type;
    } else {
      console.log('currentParams or currentParams.type is undefined');
      return 'hmaAuto'; // Default type
    }
  }
}
