import { ChangeDetectionStrategy, Component, ChangeDetectorRef, Input, OnInit ,OnDestroy, OnChanges, AfterViewChecked, ElementRef, HostListener, Renderer2, EventEmitter  } from '@angular/core';
import { Search } from '../../classes/search';
import { SearchService, IsearchResult, IsearchEntry } from '../../services/search.service';
import { SearchArgSingle } from '../../classes/search-args';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HmaTypeService, HmaType } from 'src/app/global/services/hma-type.service';
import { SearchStateService } from 'src/app/search/comp/frontpage/search-state.service';
import { WindowSizeService } from '../../../global/services/window-size.service';
import { SearchFiltersService } from './search-filters.service';

@Component({
  selector: 'app-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchFiltersComponent implements OnInit, OnDestroy {
  private resultEntriesSubject = new BehaviorSubject<IsearchEntry[]>([]);
  @Input() args;
  @Input() sort: SearchArgSingle;
  searchParams: any = {};
  totalEntries$: Observable<number>;
  hmaTypes: HmaType[];
  desktop: boolean;
  private filterSubscription: Subscription;
  veteranYear: number;
  filtersOpen$ = this.searchFiltersService.filtersOpen$;

  enabledFields: { [hmaType: string]: string[]; } = {
    hmaAuto: ['b', 'condition', 'saletype', 'fueltype', 'price', 'yearmodel', 'km', 'fylke', 'sellertype', 'chassis', 'colour', 'hk', 'numseats', 'trailerweight', 'wheeldrive', 'transmission', 'equipment', 'range'],
    hmaBoat: ['b', 'usageTime', 'motorBrand', 'category', 'saletype', 'fueltype', 'price', 'yearmodel', 'fylke', 'sellertype', 'colour', 'length', 'hk', 'numseats', 'numsleepers', 'boatMaterial', 'motorType', 'boatType', 'width', 'weight'],
    hmaMC: ['b', 'category', 'saletype', 'fueltype', 'price', 'yearmodel', 'km', 'fylke', 'sellertype', 'colour', 'ccm'],
    hmaMobileHome: ['b', 'category', 'condition', 'saletype', 'fueltype', 'price', 'yearmodel', 'km', 'fylke', 'sellertype', 'hk', 'numseats', 'numsleepers', 'trailerweight', 'length', 'width', 'weight', 'wheeldrive', 'transmission', 'bedTypes', 'heatingSystem', 'chassisManufacturer'],
    hmaCamping: ['b', 'saletype', 'price', 'yearmodel', 'fylke', 'sellertype', 'numsleepers', 'length', 'width', 'weight'],
    // All of these need its own equipment
  }


  updateLocalInputState() {
    const currentParams = this.searchStateService.getSearchParams('search-filters.component: updateLocalInputState');
    // Loop over your expected fields (e.g. the keys in this.args)
    Object.keys(this.args).forEach(key => {
      // Set the input value to the current parameter if it exists, otherwise clear it
      this.args[key].val = currentParams && currentParams[key] ? currentParams[key] : '';
    });
    // Trigger change detection if necessary
    this.cdr.markForCheck();
  }

  // Shortcut for getting the currently selected hmaType
  get hmaType() {
    const from = 'search-filtters.component.ts: get hmaType';
    const params = this.searchStateService.getSearchParams(from);
    return params && params.type ? params.type : null;
  }

  get fixedParams() {
    const from = 'search-filtters.component.ts: get FixedParams';
    const params = this.searchStateService.getSearchParams(from);
    
    if (params && params.forhandler) {
        return { forhandler: params.forhandler };
    }
    
    return null;
  }

  updateSearchParams(newParams: any) {
   this.searchStateService.appendSearchParams(newParams);
  }

  constructor(
    private hmaTypeService: HmaTypeService,
    private winSize: WindowSizeService,
    private router: Router,
    private el: ElementRef,
    private searchService: SearchService,
    private searchStateService: SearchStateService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef, 
    private searchFiltersService: SearchFiltersService,
  ) {
    this.hmaTypes = hmaTypeService.getSearchEnabledTypes();
    this.desktop = this.winSize.getAgentDevice() === 'desktop';
    const currentYear = new Date().getFullYear();
    this.veteranYear = currentYear - 30;
    this.totalEntries$ = this.searchStateService.totalEntries$.pipe(
          map(entries => entries || 0) // Ensure that null or undefined is mapped to 0
    );
  }

  ngOnInit() {
    this.searchService.searchParams$.subscribe((params) => {
      this.searchParams = params;
    });

    this.searchFiltersService.outsideClick.subscribe(() => {
      this.searchFiltersService.closeFilters();
    });
    
    // If args are provided and include a sort argument, use that
    if (this.args && this.args['sort']) {
      this.sort = this.args['sort'];
    } else {
      console.warn('Sort was not provided in args. Please initialize sort appropriately.');
    }

  }

  toggleFilters() {
    this.searchFiltersService.toggleFilters();
  }

  onFiltersClick(event: MouseEvent) {
    event.stopPropagation();
  }

  ngOnDestroy() {
    // Prevent memory leaks by unsubscribing
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  onSortChanged() {
    // Retrieve the new sort value from the bound sort object.
    const from = 'search-filters.component.ts: onSortChange';
    const newSort = this.sort.val;
    // Update the search parameters accordingly:
    let oldParams: any = this.searchStateService.getSearchParams(from);
    //console.log('search-filters.component.ts: onSortChange - oldparams: ' + oldParams); 
    let newParams: any = { ...oldParams }; // Create a copy to avoid mutating the original object

    newParams.sort = newSort;
    //newParams.page = 0; // test -> hardcode page to 0
    this.searchStateService.setStartFromPageZero(true); // set justClickedHmaButton to true to start from 0
    this.searchStateService.appendSearchParams(newParams);

    this.searchStateService.updateResultEntries(false).subscribe(
      result => {
          if (result.data.length > 0) {
              const updatedEntries = [...this.resultEntriesSubject.value, ...result.data];
              this.resultEntriesSubject.next(updatedEntries);
              this.cdr.detectChanges();
          }
          this.searchStateService.updateUrl();
          this.cdr.detectChanges();
          this.searchStateService.scrollToID('ads', 'instant');
      },
      error => {
          console.error('Error loading more entries:', error);
      }
    );
  }
  

  fieldIsEnabled(fieldName: string) {
    return this.enabledFields[this.hmaType].indexOf(fieldName) >= 0;
  }

  onMultiValChange(fieldName: string, event: { value: string }) {
    //console.log(`search-filters.component - onMultiValChange - ${fieldName}:`, event);
    const from = 'search-filtters.component.ts: get onMultiValChange';
    // Get the existing params
    let oldParams: any = this.searchStateService.getSearchParams(from);
    let newParams: any = { ...oldParams }; // Create a copy to avoid mutating the original object
  
    //console.log('search-filters.component.ts - oldParams (before update):', oldParams);
  
    // Validate event values
    let value: string = event.value !== undefined ? event.value : null;
    //console.log('value:' + value);
  
    newParams[fieldName] = value;
    //newParams.page = 0; // test -> hardcode page to 0
    this.searchStateService.setStartFromPageZero(true); // set justClickedHmaButton to true to start from 0
  
    if (value === null) {
      //console.log('Value === null');
      delete newParams[fieldName]; // Use the new clear method
      this.searchStateService.appendSearchParams(newParams);
    }
  
    //console.log('search-filters.component.ts: onMultiValChange - newParams:', newParams);
     this.searchStateService.appendSearchParams(newParams);
    
    // Update the results after scrolling
    this.searchStateService.updateResultEntries(false).subscribe(
      result => {
        if (result.data.length > 0) {
          const updatedEntries = [...this.resultEntriesSubject.value, ...result.data];
          this.resultEntriesSubject.next(updatedEntries);
          this.cdr.detectChanges();
        }
        this.searchStateService.updateUrl();
        this.cdr.detectChanges();
        this.searchStateService.scrollToID('ads', 'instant');
      },
      error => {
        console.error('Error loading more entries:', error);
      }
    );
  }

  onValueChange(fieldName: string, event: { min?: number; max?: number }) {
    //console.log(`search-filters.component - onValueChange - ${fieldName}:`, event);

    // Get the existing params
    const from = 'search-filtters.component.ts: get onValueCHange';
    let oldParams: any = this.searchStateService.getSearchParams(from);
    let newParams: any = { ...oldParams }; // Create a copy to avoid mutating the original object

    //console.log('search-filters.component.ts - onValueChange -  getParams (before update):', oldParams);

    // Validate event values
    let min = event.min !== undefined ? event.min : null;
    let max = event.max !== undefined ? event.max : null;

    if (!min && !max) {
      this.searchStateService.deleteField(fieldName);
      return;
    }

    // If both values are missing, remove the field from params
   if (min !== null && max !== null) {
        newParams[fieldName] = `${min}-${max}`;
    } else if (min !== null) {
        newParams[fieldName] = `${min}-`;
    } else if (max !== null) {
        newParams[fieldName] = `-${max}`;
    }

    //newParams.page = 0; // test -> hardcode page to 0
    this.searchStateService.setStartFromPageZero(true); //
    //console.log('search-filters-component.ts - onValueChange - New Params (after update):', newParams);
    this.searchStateService.appendSearchParams(newParams);
    
    // Update the results
    this.searchStateService.updateResultEntries(false).subscribe(
        result => {
            if (result.data.length > 0) {
                const updatedEntries = [...this.resultEntriesSubject.value, ...result.data];
                this.resultEntriesSubject.next(updatedEntries);
                this.cdr.detectChanges();
            }
            this.searchStateService.updateUrl();
            this.cdr.detectChanges();
            this.searchStateService.scrollToID('ads', 'instant');
        },
        error => {
            console.error('Error loading more entries:', error);
        }
    );
  }

}
