import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '../../../global/services/user.service';
import { NotificationService } from '../../../global/services/notification.service';

declare let gtag: Function;

@Component({
  selector: 'app-register',
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: [''],
      acceptTerms: ['', [Validators.requiredTrue]],
      recaptcha: ['', [Validators.required]],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  onSubmit(captchaResponse: string) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.loading = true;
    this.userService.register(this.registerForm.value, captchaResponse)
      .pipe(first())
      .subscribe(
        data => {
          localStorage.setItem('registerEmail', this.registerForm.controls.email.value);

          gtag('event', 'register', {
            method: 'successful registration',
          });

          this.router.navigate(['/bruker/validerepost']);
          
        },
        error => {
          this.loading = false;

          if (error.status === 409) {
            this.notificationService.errorMessage('Denne e-posten er allerede registrert.');
            return;
          }

          gtag('event', 'failed-register', {
            method: 'failed registration',
          });

          this.notificationService.errorMessage(error.message);
        });
  }
}
