import { Component, ChangeDetectorRef, OnInit, Renderer2, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { SearchService, IsearchResult, IsearchEntry } from '../../services/search.service';
import { Search } from '../../classes/search';
import { Observable ,  Subscription, timer, BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { CurrSearchService } from '../../services/curr-search.service';
import { debounce, map } from 'rxjs/operators';
import { WindowSizeService } from '../../../global/services/window-size.service';
import { ResourceService } from '../../../global/services/resource.service';
import { TemplateDataService } from '../../../global/services/template-data.service';
import { HmaTypeService } from 'src/app/global/services/hma-type.service';
import { multivalData } from '../../../global/static/multi-val-data';
import { ArticleService, Article } from 'src/app/articles/services/article.service';
import { MetaTagsService } from 'src/app/global/services/metatags.service';
import { InpSingleComponent } from '../input/inp-single/inp-single.component';
import { SearchStateService } from './search-state.service';
//import { spawn } from 'child_process';
//import { GoogleAdsService } from 'src/app/global/services/google-ads.service';

declare var googletag: any;  // Ensure googletag is available

@Component({
  selector: 'app-frontpage',
  templateUrl: './frontpage.component.html',
  styleUrls: ['./frontpage.component.css']
})
export class FrontpageComponent implements OnInit, AfterViewInit {
  private resultEntriesSubject = new BehaviorSubject<IsearchEntry[]>([]);
  private lastType: string | null = null; // Store last checked type
  currentType: string;
  showAds = true;
  
  resultEntries$ = this.searchStateService.resultEntries$;
  @ViewChild('adWrapper') adWrapper!: ElementRef;
  @ViewChild('leaderBoardAdWidth') leaderBoardAdWidth!: ElementRef;
  @ViewChild('mainContent') mainContent!: ElementRef;
  @ViewChild('buttonsWrapper') buttonsWrapper!: ElementRef;
  @ViewChild('animatedText') animatedText!: ElementRef;
  @ViewChild(InpSingleComponent) inpSingleComponent: InpSingleComponent;

  args = {
    t: { val: '', onChange: (val: string) => {} } // Example structure for this.args['t']
  };

  search: Search;
  //args: {} = {};
  opened = false;
  argsChanged$: Subscription;
  //resultEntries: IsearchEntry[] = [];
  
  recommended$: Observable<IsearchResult>;
  screenSize: string;
  
  articles: Article[] = [];
  adSlots: { id: string; path: string; size: any[] }[] = []; 
  values;
  currModels;
  hmaTypes;


  selectedType: string = 'hmaAuto';
  selectedBrand : string = '';
  selectedModel : string = '';
  veteranYear: number; 
  private hasAdjusted = false;
  private routerSubscription: Subscription | undefined;
  private adsInitialized = false;

  // Add a property to store the current time
  currentTime: Date = new Date();

  constructor(
    private searchStateService: SearchStateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private currSearchService: CurrSearchService,
    private searchService: SearchService,
    private windowSize: WindowSizeService,
    private resSvc : ResourceService,
    private templateData: TemplateDataService,
    private hmaTypeService: HmaTypeService,
    private articleService: ArticleService,
    private metatags: MetaTagsService,
    private renderer: Renderer2, 
    private cdr: ChangeDetectorRef, 
    //private googleAdsService: GoogleAdsService
  )
  {
    this.currentType = this.getInitialType();
    const currentYear = new Date().getFullYear();
    this.veteranYear = currentYear - 30;
    this.values = resSvc.getPopData('b', this.selectedType );
    this.templateData.setShowFooter(false);
    this.screenSize = this.windowSize.getScreenSize();

    // Update current time every second
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);

  }


  reloadAds() {
    this.showAds = false;
    setTimeout(() => {
      this.showAds = true;
    }, 10); // Short delay to ensure Angular detects the change
  }

  // Method to get the initial type
  getInitialType(): string {
    const currentParams = this.searchStateService.getSearchParams('called from frontpage');
    if (currentParams && currentParams.type) {
      this.currentType = currentParams.type;
      return this.currentType;
    } else {
      console.log('currentParams or currentParams.type is undefined');
      return undefined;
    }
  }

  // Method to update the current type
  updateCurrentType(): string {
    const currentParams = this.searchStateService.getSearchParams('called from frontpage');
    if (currentParams && currentParams.type) {
      if (currentParams.type === 'hmaAuto' && currentParams.yearmodel === `1900-${this.veteranYear}`) {
          this.currentType = 'hmaVeteran'; // return hmaVeteran if true
      } else {
          this.currentType = currentParams.type;
      }
      return this.currentType;
  } else {
      console.log('currentParams or currentParams.type is undefined');
      return undefined;
    }
  }

  addAnimationClass(): void {
    if (this.buttonsWrapper) {
      const span = this.buttonsWrapper.nativeElement.querySelectorAll('.button span');
      const em = this.buttonsWrapper.nativeElement.querySelectorAll('.button em');
      em.forEach((button: HTMLElement) => {
        this.renderer.addClass(button, 'load-animation-played');
      });
      span.forEach((button: HTMLElement) => {
        this.renderer.addClass(button, 'load-animation-played');
      });
    }
  }

  currentSearchParams: any = {};

  onSearchHero(searchtext: string, currentParams?: any, ) {

    this.searchStateService.setPagesLoadedBatch(0);
    this.executeSearchLogic(searchtext);
  
  }

  private executeSearchLogic(searchtext: string) {
    let stringifySearchtext: string;

    this.resultEntriesSubject.next([]); // clear old results
    this.searchStateService.setHasMoreResults(true); // set hasMoreResults to true

    this.searchStateService.clearPageParam(true);

    const from = 'frontpage.component.ts: get executeSearchLogic';
    this.currentSearchParams = this.searchStateService.getSearchParams(from);
    let hmaType = this.currentSearchParams.type;

    if (searchtext) {
        // Check the structure of searchtext to identify the property containing the text
        if (searchtext && typeof searchtext === 'object' && 't' in searchtext) {
            const searchTextString = (searchtext as { t: string }).t;
            stringifySearchtext = searchTextString; // Use the text directly (no need to stringify)
        } else {
            console.error('searchtext does not contain a valid text property:', searchtext);
            return; // Exit if searchtext is invalid
        }
    } else {
        console.error('searchtext is undefined or null');
        return; // Exit if searchtext is undefined or null
    }


    // Create queryParams with searchtext included
    const queryParams: any = { ...this.currentSearchParams, t: stringifySearchtext };

    this.searchStateService.appendSearchParams(queryParams);
    this.searchStateService.setStartFromPageZero(true);
    console.log('executeSearchLogic hmaType:' + hmaType);
    console.log('executeSearchLogic searchtext:' + JSON.stringify(searchtext));
    const isSearch = true; 
    // Pass queryParams to updateParamsThenScroll
    this.updateParamsThenScroll(hmaType, searchtext, isSearch);
}

  updateParamsThenScroll(type: string | null, searchtext?: string, isSearch?) {
    const from = 'frontpage.component.ts: updateParamsThenScroll';
    this.currentSearchParams = this.searchStateService.getSearchParams(from);
    let queryParams: any = { ...this.currentSearchParams };
    
    if (type) {

      if (isSearch === true) {
        console.log ('isSearch= true');
        for (const key in queryParams) {
          if (key !== 'type' && key !== 't') delete queryParams[key]; // delete all queryParams except type and text
        }
        console.log ('isSearch= QueryParams: ' + JSON.stringify(queryParams));
      } else {
        console.log ('isSearch= false');
        for (const key in queryParams) {
          if (key !== 'type') delete queryParams[key]; // delete all queryParams except type
        }
        console.log ('isSearch. QueryPArams:' + JSON.stringify(queryParams));
      }


      switch (type) {
        case 'hmaAuto':
          queryParams.type = 'hmaAuto';
          delete queryParams.yearmodel;
          break;
        case 'hmaMobileHome':
          queryParams.type = 'hmaMobileHome';
          delete queryParams.yearmodel;
          break;
        case 'hmaCamping':
          queryParams.type = 'hmaCamping';
          delete queryParams.yearmodel;
          break;
        case 'hmaMC':
          queryParams.type = 'hmaMC';
          delete queryParams.yearmodel;
          break;
        case 'hmaVeteran':
          queryParams.type = 'hmaAuto';
          queryParams.yearmodel = '1900-' + this.veteranYear;
          break;
        case 'hmaBoat':
          queryParams.type = 'hmaBoat';
          delete queryParams.yearmodel;
          break;
        default:
          console.warn('Unknown type:', type);
          return;
      }
    }

    this.clearEntries();
    this.searchStateService.setNewSearchParams(queryParams);
    this.performSearch(queryParams);
  }

  performSearch(queryParams: any) {
    this.searchStateService.clearPageParam();
    this.searchStateService.setStartFromPageZero(true);
    // Perform the search and update result entries
    this.searchStateService.updateResultEntries(false).subscribe(
      result => {
        if (result.data.length > 0) {
          // Create a new array by combining existing entries and new entries
          const updatedEntries = [...this.resultEntriesSubject.value, ...result.data];
          this.resultEntriesSubject.next(updatedEntries); // Update the BehaviorSubject with a new array
          this.cdr.detectChanges(); // Trigger change detection manually
        } else {

        }
        this.searchStateService.replaceUrl(queryParams);
        this.cdr.detectChanges();
        this.searchStateService.scrollToID('ads', 'smooth');
      },
      error => {
        console.error('Error loading more entries:', error);
      }
    );
  }

  clearEntries() {
    this.searchStateService.clearSearchParams();
  }

  ngOnInit() {

    this.searchStateService.searchParams$.subscribe(() => {
      this.reloadAds();
    });

    this.activatedRoute.queryParams.subscribe(params => {
      // Check if there are any parameters
      if (Object.keys(params).length > 0) {
        console.log('User entered the page with the following URL parameters:', params);
        //this.searchStateService.setPagesLoadedBatch(0);
        this.searchStateService.setStartFromPageZero(true);
        this.searchStateService.setNewSearchParams(params); // set parameters user entered page with in searchstateservice
        this.searchStateService.updateResultEntries(false).subscribe(
          result => {
            if (result.data.length > 0) {
              // Create a new array by combining existing entries and new entries
              const updatedEntries = [...this.resultEntriesSubject.value, ...result.data];
              this.resultEntriesSubject.next(updatedEntries); // Update the BehaviorSubject with a new array
              this.cdr.detectChanges(); // Trigger change detection manually
            } else {
    
            }
            this.searchStateService.replaceUrl(params);
            this.cdr.detectChanges();
            this.searchStateService.scrollToID('ads', 'smooth');
          },
          error => {
            console.error('Error loading more entries:', error);
          }
        );
      } else {
        //console.log('No URL parameters found. setting default');
        //const defaultParams = {
          //type: 'hmaAuto'
        //};  
        //this.searchStateService.setPagesLoadedBatch(0);
        this.searchStateService.setStartFromPageZero(true);
        //this.searchStateService.appendSearchParams(defaultParams);
      }
    });

    
    const currentParams = this.searchStateService.getSearchParams('called from frontpage');
    if (currentParams && currentParams.type) {
      this.currentType = currentParams.type;
    } else {
      console.log('currentParams or currentParams.type is undefined');
    }
    
  

    setTimeout(() => {
      this.addAnimationClass();
    }, 3000); // 
    
    this.metatags.setTitle('Norges største gratis annonseportal for kjøretøy');
    this.metatags.setTagValues({
      'og:description': 'AutoDB.no - Norges største gratis annonseportal for kjøretøy - Finn eller selg din bil, campingvogn, bobil, motorsykkel eller båt',
      'description': 'AutoDB.no - Norges største gratis annonseportal for kjøretøy - Finn eller selg din bil, campingvogn, bobil, motorsykkel eller båt',
      'og:image': 'https://autodb.no/assets/img/autodb-logo.png',
    });
    this.metatags.addMetaTags();
    this.articles = this.articleService.getArticles();
    this.search = this.currSearchService.get();
    this.hmaTypes = this.hmaTypeService.getSearchEnabledTypes();
  }

  checkSize(name: 'mobile' | 'desktop') {
    return this.windowSize.checkScreenSize(name);
  }

  setCurrModel()
  {
    //this.currModels = this.values[this.selectedBrand];
    this.currModels = multivalData['b'][this.selectedType][this.selectedBrand];
    //console.log(this.selectedType,this.selectedBrand, this.currModels);
  }

  selectType(type: string) {
    this.selectedType = type;
    this.selectedModel = '';
    this.selectedBrand = '';
    this.values = this.resSvc.getPopData('b', type );

  }
  

  ngAfterViewInit() {

    const animatedTextElement = this.animatedText.nativeElement;
    const inputElement = document.querySelector('app-inp-single .form-group .form-control') as HTMLInputElement;

    // Click event on animated text
    animatedTextElement.addEventListener('click', () => {
      hideAnimatedText();
      if (inputElement) {
        inputElement.focus();
      }
    });

    // Focus event on input field (including tabbing into it)
    if (inputElement) {
      inputElement.addEventListener('focus', () => {
        hideAnimatedText();
      });
    }

    // Function to hide animated text
    function hideAnimatedText() {
      animatedTextElement.classList.add('clicked');
      animatedTextElement.style.display = 'none';
      animatedTextElement.style.opacity = '0';
      animatedTextElement.style.pointerEvents = 'none';
    }

    // Add sequential animations
    // Add the 'dots' animation when the 'fade-in' animation ends
    animatedTextElement.addEventListener('animationend', () => {
      // Add the 'dots' animation class
      animatedTextElement.classList.add('dots');

      // Now listen for the 'dots' animation to end
      animatedTextElement.addEventListener('animationend', () => {
        // Add the 'moretext' animation class after 'dots' animation ends
        animatedTextElement.classList.add('pause');

        animatedTextElement.addEventListener('animationend', () => {
          // Add the 'moretext' animation class after 'dots' animation ends
          animatedTextElement.classList.add('more-text');

          animatedTextElement.addEventListener('animationend', () => {
            // Add the 'moretext' animation class after 'dots' animation ends
            animatedTextElement.classList.add('even-more-text');

            animatedTextElement.addEventListener('animationend', () => {
              // Add the 'moretext' animation class after 'dots' animation ends
              animatedTextElement.classList.add('rest-of-text');
            });
          });
        });
      });
    });
  }
  
  ngOnDestroy() {
    this.templateData.setShowFooter(true);
  }
  

  isDay(): boolean {
    const hours = this.currentTime.getHours();
    return hours >= 6 && hours < 18;
  }

}
