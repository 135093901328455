import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { IsearchEntry } from '../../services/search.service';

@Component({
  selector: 'app-result-entry-small',
  templateUrl: './result-entry-small.component.html',
  styleUrls: ['./result-entry-small.component.scss'],
})
export class ResultEntrySmallComponent implements OnInit, AfterViewInit {
  @Input() entry: IsearchEntry;
  @ViewChild('variant') variant!: ElementRef;
  @ViewChild('sellerName') sellerName!: ElementRef;
  @ViewChild('brand') brand!: ElementRef;
  @ViewChild('model') model!: ElementRef;

  truncatedText: string = '';
  truncatedSellerName: string = '';
  truncatedBrand: string = '';
  truncatedModel: string = '';

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.cdr.detectChanges();

      //console.log('Entry Data:', this.entry);

      if (this.variant) this.truncateText(this.variant, 250, 'variant');
      if (this.sellerName) this.truncateText(this.sellerName, 195, 'sellerName');
      if (this.brand) this.truncateText(this.brand, 250, 'brand');
      if (this.model) this.truncateText(this.model, 250, 'model');
    }, 500);
  }

  truncateText(elementRef: ElementRef, maxWidth: number, type: 'variant' | 'sellerName' | 'brand' | 'model') {
    if (!elementRef) return;
  
    const element = elementRef.nativeElement;
    
    let originalText = '';
    if (type === 'variant') {
      originalText = (this.entry.variant !== undefined && this.entry.variant !== null) ? this.entry.variant : ''; 
    } else if (type === 'sellerName') {
      originalText = (this.entry.sellerName !== undefined && this.entry.sellerName !== null) ? this.entry.sellerName : ''; 
    } else if (type === 'brand') {
      originalText = (this.entry.brand !== undefined && this.entry.brand !== null) ? this.entry.brand : ''; 
    } else if (type === 'model') {
      originalText = this.entry.model;
      originalText = (this.entry.model !== undefined && this.entry.model !== null) ? this.entry.model : ''; 
    }
  
    let tempText = originalText;
    element.innerText = originalText; // Set full text to measure width
  
    while (element.scrollWidth > maxWidth && tempText.length > 0) {
      tempText = tempText.slice(0, -1); // Remove one character at a time
      element.innerText = tempText + '...';
    }

    //console.log(`${type} Text: ${originalText}`);
    //console.log(`scrollWidth: ${element.scrollWidth}, offsetWidth: ${element.offsetWidth}, maxWidth: ${maxWidth}`);

  
    if (type === 'variant') {
      this.truncatedText = tempText + (tempText.length < originalText.length ? '...' : '');
    } else if (type === 'sellerName') {
      this.truncatedSellerName = tempText + (tempText.length < originalText.length ? '...' : '');
    } else if (type === 'brand') {
      this.truncatedBrand = tempText + (tempText.length < originalText.length ? '...' : '');
    } else if (type === 'model') {
      this.truncatedModel = tempText + (tempText.length < originalText.length ? '...' : '');
    }
  }
  
}
