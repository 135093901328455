import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SearchService, IsearchResult, IsearchEntry } from '../../../services/search.service';
import { SearchArgSingle } from '../../../classes/search-args';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchStateService } from 'src/app/search/comp/frontpage/search-state.service';

@Component({
  selector: 'app-inp-single',
  templateUrl: './inp-single.component.html',
  styleUrls: ['./inp-single.component.css']
})
export class InpSingleComponent implements OnInit {
  private resultEntriesSubject = new BehaviorSubject<IsearchEntry[]>([]);
  resultEntries$: Observable<IsearchEntry[]> = this.resultEntriesSubject.asObservable();

  @Input() realTimeTrackingEnabled: boolean = false;
  @Input() arg: SearchArgSingle;
  @Input() label: string;
  @Input() title: string;
  @Input() inlineLabel = false;

  // Add an @Output event emitter
  @Output() searchParamsChanged = new EventEmitter<any>();

  constructor(
    private searchStateService: SearchStateService,
  ) { }

  ngOnInit() {
    // Retrieve current search parameters from the service
    const from = 'inp-single.component - ngOnInit'
    const currentSearchParams = this.searchStateService.getSearchParams(from);
    if (currentSearchParams && currentSearchParams.t && currentSearchParams.t.trim() !== '') {
      // Populate the input value if available and not empty
      this.arg.val = currentSearchParams.t;
      // Optionally, update the shared search text too:
      this.searchStateService.updateSearchText(currentSearchParams.t);
    }
  
    // Subscribe to further changes in the shared search text
    this.searchStateService.searchText$.subscribe((text) => {
      if (this.arg && text !== this.arg.val) { // Only update if the new text is different
        this.arg.val = text; // Update the local input value
      }
    });
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.keyCode === 13) {
      this.onChange();
      event.preventDefault(); // Prevent default behavior if needed
    }
  }

  onChange(triggeredByInput: boolean = false) {
    if (this.arg) {
      this.arg.onChange(this.arg.val);
      const rawArg = this.arg.val;

      // Wrap the value in an object with the key 't'
      const searchParams = { t: rawArg };

      //console.log('onChange in inp-single.component:', searchParams);

      // Emit the searchParams object to the parent component
      if (!triggeredByInput || (triggeredByInput && this.realTimeTrackingEnabled)) {
        this.searchParamsChanged.emit(searchParams);
      }

      // Update the shared search text in the service
      this.searchStateService.updateSearchText(rawArg);
    }
  }

  // Add the clearInput method
  clearInput() {
    if (this.arg) {
      this.arg.val = ''; // Clear the input value
      this.onChange(); // Trigger the onChange method to update the search params
      this.searchStateService.updateSearchText(''); // Clear the shared search text
    }
  }
}