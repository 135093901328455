import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { SearchService, IsearchResult, IsearchEntry } from '../../services/search.service';
import { MappingService } from './mapping.service';

@Injectable({
  providedIn: 'root', // Make the service available globally
})
export class SearchStateService {
    private searchTextSubject = new BehaviorSubject<string>(''); // Holds the current search text
    searchText$ = this.searchTextSubject.asObservable(); // Observable for components to subscribe to

    private resultEntriesSubject = new BehaviorSubject<IsearchEntry[]>([]);
    resultEntries$ = this.resultEntriesSubject.asObservable();

    private searchParamsSubject = new BehaviorSubject<any>({});
    searchParams$ = this.searchParamsSubject.asObservable();

    private startFromPageZeroSubject = new BehaviorSubject<boolean>(false);
    justClickedHmaButton$ = this.startFromPageZeroSubject.asObservable();

    private _hasMoreResultsSubject = new BehaviorSubject<boolean>(true);
    public hasMoreResults$ = this._hasMoreResultsSubject.asObservable();

    private pagesLoadedBatch = 0;
    pagesLoadedBatch$ = new BehaviorSubject<number>(0);

    private totalEntriesSubject = new BehaviorSubject<number>(0);
    totalEntries$ = this.totalEntriesSubject.asObservable();

    /*
    private filterOpenSubject = new BehaviorSubject<boolean>(true);
    filterOpen$ = this.filterOpenSubject.asObservable();
    */

    private lastSelectedType: string | null = null;

    constructor(
        private searchService: SearchService,
        private router: Router,
        private route: ActivatedRoute,
        private viewportScroller: ViewportScroller,
        private mappingService: MappingService
    ) {
        this.searchParams$.subscribe(params => {
            if (params && params.type) {
                this.lastSelectedType = params.type;
                //console.log('lazyLoadFix: searchStateService - lastSelectedType updated:', this.lastSelectedType);
              }
        });
    }

    clearEntries() {
        //console.log('Clearing entries!');
        this.resultEntriesSubject.next([]);
    }

    updateSearchText(searchText: string) {
        this.searchTextSubject.next(searchText);
      }
    
      /*
    setFilterOpen(isOpen: boolean): void {
        this.filterOpenSubject.next(isOpen);
    }
      */
    setPagesLoadedBatch(value: number): void {
        //console.log('search-state-service.ts - setPagesLoadedBatch called! value: ' + value);
        this.pagesLoadedBatch = value;
        this.pagesLoadedBatch$.next(this.pagesLoadedBatch);
    }
    
    getPagesLoadedBatch(): number {
        //console.log('search-state-service.ts - getPagesLoadedBatch called!');
        return this.pagesLoadedBatch;
    }
    
    setHasMoreResults(value: boolean) {
        //console.log('search-state-service.ts - setHasMoreResults called! value:' + value); 
        this._hasMoreResultsSubject.next(value);
    }


    // Method to update search parameters
    appendSearchParams(params: any) {
        
        console.log('search-state-service.ts -  xx Before appendSearchParams, current state:', JSON.stringify(this.searchParamsSubject.getValue()));
        
        // Force a new object reference
        const updatedParams = { ...this.searchParamsSubject.getValue(), ...params };
    
        //console.log('search-state-service.ts - xx New object reference check:', updatedParams === this.searchParamsSubject.getValue() ? 'SAME' : 'DIFFERENT');
        
        this.searchParamsSubject.next(updatedParams);
        this.updateUrl();
        console.log('search-state-service.ts - xx After appendSearchParams, current state:', JSON.stringify(this.searchParamsSubject.getValue()));
    }

    setNewSearchParams(params: any) {
        console.log('Before setNewSearchParams, current state:', JSON.stringify(this.searchParamsSubject.getValue()));
    
        // Reset the subject before updating (to clear old params)
        this.searchParamsSubject.next({});
    
        // Force a new object reference with updated params
        const updatedParams = { ...params };
    
        // Now set the new params
        this.searchParamsSubject.next(updatedParams);
        
        // Ensure URL is updated correctly
        this.replaceUrl(updatedParams);
    
        console.log('After setNewSearchParams, current state:', JSON.stringify(this.searchParamsSubject.getValue()));
    }
    

    getSearchParams(from: string) {
        const params = this.searchParamsSubject.value;
        //console.log('getSearchParams called from:' +  from + '! params:' + JSON.stringify(params));
        return params;
    }

    getLastSelectedType(): string | null {
        return this.lastSelectedType;
    }

    // Method to update result entries
    updateResultEntries(isLoadMore: boolean = false): Observable<IsearchResult> {

        //const params = this.searchParamsSubject.value;
        const params = this.getSearchParams('updateResultEntries');
        const currentEntries = this.resultEntriesSubject.value;
        if (!isLoadMore) {
            this.setPagesLoadedBatch(0);
            this.setStartFromPageZero(true);
            delete params.page;
        }
        
        this.mappingService.washParams(params);

        return this.searchService.doSearch(params).pipe(
            tap((res: IsearchResult) => {
                let updatedEntries: IsearchEntry[];

                if (params.page && Number(params.page) > 0) {
                    updatedEntries = [...currentEntries, ...res.data];
                } else {
                    updatedEntries = res.data;
                }

                this.resultEntriesSubject.next(updatedEntries);
                this.totalEntriesSubject.next(res.count);
            
            })
        );
        
    }
    

    updateUrl() {
        const params = this.searchParamsSubject.value;
        const paramsMinusPage = { ...params };
        delete paramsMinusPage.page;
        const newUrl = this.router.createUrlTree([], {
        relativeTo: this.route,
        queryParams: paramsMinusPage,
        queryParamsHandling: 'merge' // 
        }).toString();
        window.history.replaceState({}, '', newUrl); // ✅ Updates URL without triggering navigation
    }

    replaceUrl(updatedParams) {
        const params = updatedParams;
        const paramsMinusPage = { ...params };
        delete paramsMinusPage.page;
        console.log('replaceUrl.. params: ' + JSON.stringify(params));
        const newUrl = this.router.createUrlTree([], {
            relativeTo: this.route,
            queryParams: paramsMinusPage
        }).toString();
    
        window.history.replaceState({}, '', newUrl);
    }

    scrollToID(elementId: string, behaviour: string ) {
        const target = document.getElementById(elementId);
        if (behaviour === 'smooth') {
            target.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (behaviour === 'instant'){
            target.scrollIntoView({ behavior: 'auto', block: 'start' });
        }   
    }

    scrollToSamePosition(scrollPosition: number | [number, number]) {
        console.log('???Instant scrolling to scrollPosition:', scrollPosition);
    
        // ✅ Convert `scrollPosition` to a tuple if it's a single number
        const finalScrollPosition: [number, number] = 
            Array.isArray(scrollPosition) ? scrollPosition : [0, scrollPosition];
    
        console.log('???Final scroll position:', finalScrollPosition);
    
        // ✅ Disable smooth scrolling for an instant scroll
        document.documentElement.style.scrollBehavior = 'auto';
    
        setTimeout(() => {
            this.viewportScroller.scrollToPosition(finalScrollPosition);
    
            // ✅ Restore smooth scrolling behavior after a short delay
            setTimeout(() => {
                document.documentElement.style.scrollBehavior = 'smooth';
            }, 300);
        }, 100);
    }
    

    // Method to clear search parameters and results
    clearSearchParams() {
        //console.log('search-state-service.ts - clearSearchParams called!');
        this.searchParamsSubject.next({});
        this.resultEntriesSubject.next([]);
    }



    clearSearchParamsExceptText() {
        //console.log('search-state-service.ts - clearSearchParamsExceptText called!');
        const currentParams = this.searchParamsSubject.value;

        if (currentParams && currentParams.t) {
            // Keep only the type parameter
            this.searchParamsSubject.next({ t: currentParams.t });
        } else {
            // If no type exists, clear everything
            this.searchParamsSubject.next({});
        }

        //console.log('search-state-service.ts - Search params after clearing:', this.searchParamsSubject.value);
    }

    deleteField(fieldName: string) {
        //console.log(`search-state-service.ts - deleteField called for ${fieldName}!`);
        
        let currentParams = this.searchParamsSubject.value;
        
        if (Object.isFrozen(currentParams)) {
            currentParams = { ...currentParams }; // Create a shallow copy to allow modifications
        }

        
        if (currentParams && fieldName in currentParams) {

            if (typeof fieldName === 'string') {
                //console.log('is string');
                delete currentParams[fieldName]; // delete the field completely
            } else {
                let deleteMe = JSON.stringify(currentParams[fieldName]);
                //console.log('is NOT string');
                //console.log('deleteMe= ' + deleteMe);
                //delete currentParams[fieldName]; // delete the field completely
                return;
            }

            this.appendSearchParams(currentParams);
            this.searchParamsSubject.next({ ...currentParams }); // Update state
        }
    
        //console.log(`search-state-service.ts - Params after clearing "${fieldName}":`, this.searchParamsSubject.value);
    }

    clearPageParam(onlyOnHmaClick = false) {
        let currentParams = { ...this.searchParamsSubject.value };
        //console.log('search-state-service.ts - clearPageParam called! - Current params:', JSON.stringify(currentParams));

        // Remove 'page' only when explicitly required
        if ('page' in currentParams) {
            delete currentParams.page;
        }

        //console.log('search-state-service.ts - clearPageParam called! - new params:', JSON.stringify(currentParams));

        // Force state update
        this.appendSearchParams(currentParams);
        this.searchParamsSubject.next(currentParams);

        //console.log('search-state-service.ts - clearPageParam called! - Search params after removing page:', this.searchParamsSubject.value);
    }

    setStartFromPageZero(value: boolean) {
        //console.log('search-state-service.ts - setStartFromPageZero called! value: ' + value);
        this.startFromPageZeroSubject.next(value);
    }

    getStartFromPageZero(): boolean {
        //console.log('search-state-service.ts - getStartFromPageZero called! value: ' + this.startFromPageZeroSubject.value);
        return this.startFromPageZeroSubject.value;
    }
}
