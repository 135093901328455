import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { SearchArgSingle } from '../../../classes/search-args';

@Component({
  selector: 'app-sort-select',
  templateUrl: './sort-select.component.html',
  styleUrls: ['./sort-select.component.scss']
})
export class SortSelectComponent implements OnInit {

  @Input() arg: SearchArgSingle;
  @Input() searchParams;
  @Output() sortChanged: EventEmitter<any> = new EventEmitter();

  allOptions = [
    { value: 'published', name: 'Publisert' },
    { value: 'price-asc', name: 'Pris - lav/høy' },
    { value: 'price-desc', name: 'Pris - høy/lav' },
    { value: 'km-asc', name: 'Km - lav/høy' },
    { value: 'km-desc', name: 'Km - høy/lav' },
    { value: 'hk-asc', name: 'HK - lav/høy' },
    { value: 'hk-desc', name: 'HK - høy/lav' },
    { value: 'yearmodel-desc', name: 'År - nyest/eldst' },
    { value: 'yearmodel-asc', name: 'År - eldst/nyest' },
  ];

  freetextOptions = [
    { value: 'relevance', name: 'Relevans' }
  ];

  get options(): Array<object> {
    return this.allOptions;
  }

  constructor() { }

  ngOnInit() {
    //console.log('SortSelectComponent: arg = ', this.arg);
    //console.log('SortSelectComponent: searchParams = ', this.searchParams);
    //console.log('Available options:', this.options);
  }

  onChange() {
    this.arg.onChange(this.arg.val);
    this.sortChanged.emit(this.arg.val);
    //console.log('SortSelectComponent: arg = ', this.arg);
    //console.log('SortSelectComponent: searchParams = ', this.searchParams);
    //console.log('Available options:', this.options);
  }
}
