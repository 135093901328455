import { Component, HostListener, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
  isMenuOpen = false;
  dropdownOpen = false;

  @ViewChild('burgerButton') burgerButton!: ElementRef;
  @ViewChild('mainMenu') mainMenu!: ElementRef;

  toggleMenu(event: Event) {
    event.stopPropagation(); // Prevent the click event from bubbling up
    this.mainMenu.nativeElement.classList.toggle('open');
    this.burgerButton.nativeElement.classList.toggle('opened');
    this.isMenuOpen = !this.isMenuOpen;
  }

  toggleDropdown(event: Event): void {
    event.preventDefault(); // Prevent navigation if needed
    event.stopPropagation(); // Prevent the click event from bubbling up
    this.dropdownOpen = !this.dropdownOpen;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    const target = event.target as HTMLElement;
    if (this.mainMenu && !this.mainMenu.nativeElement.contains(target) && !target.closest('.main-menu') && !target.closest('.dropdown-menu')) {
      if (this.mainMenu.nativeElement.classList.contains('open')) {
        this.mainMenu.nativeElement.classList.remove('open');
        this.isMenuOpen = false;
        this.dropdownOpen = false; // Close the dropdown if the menu is closed
        this.burgerButton.nativeElement.classList.remove('opened');
      }
    }
  }
}
