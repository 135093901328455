import { Component, ViewChildren, ChangeDetectorRef, Input, Output, OnInit, ViewChild, ElementRef, AfterViewChecked, OnDestroy, AfterViewInit, HostListener, EventEmitter, QueryList } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Search } from '../../classes/search';
import { SearchService, IsearchEntry, IsearchResult } from '../../services/search.service';
import { SearchArgSingle } from '../../classes/search-args';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription, Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WindowSizeService } from '../../../global/services/window-size.service';
import { SearchStateService } from 'src/app/search/comp/frontpage/search-state.service';

declare var googletag: any;

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {
  hasMoreResults: boolean = true;
  @ViewChildren('adContainer') adContainers: QueryList<ElementRef>;
  @Input() search: Search;
  @Input() sort: SearchArgSingle;
  @Input() page: SearchArgSingle;
  searchParams: any = {};
  @Input() resultEntries$: Observable<IsearchEntry[]>;
  @Output() adInView: EventEmitter<number> = new EventEmitter();
  private resultEntriesSubject = new BehaviorSubject<IsearchEntry[]>([]);

  @ViewChild('sentinel') sentinel: ElementRef;
  private observer: IntersectionObserver;
  private lastObservedSentinel: Element;

  adSlots: { id: string; path: string; size: any[] }[] = [];
  currentPage = 0;
  totalLoaded = 0;
  showLoadMore: boolean = false;
  showCounter: boolean;
  veteranYear: number;
  private adsInitialized = false;
  public adDivIds = {
    leaderBoardAdId: '',
    mediumRectangleAdId: '',
    mobileSRP: '',
  };

  private routeSubscription: Subscription;
  private allEntries: IsearchEntry[] = [];
  private loading = false;
  private isFirstObservation = true;
  currentSearchParams: any;
  paramsIsFetched: boolean = false;
  justClickedHmaButton = false;
  outputQuery: any;
  currentQuery: any;
  totalEntries$: Observable<number>;

  constructor(
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private windowSize: WindowSizeService,
    private searchService: SearchService,
    private searchStateService: SearchStateService,
    private viewportScroller: ViewportScroller,
  ) {
    const currentYear = new Date().getFullYear();
    this.veteranYear = currentYear - 30;

    this.totalEntries$ = this.searchStateService.totalEntries$.pipe(
      map(entries => entries || 0)
    );

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log("Navigation ended, reloading entries...");
        this.loadMore(this.viewportScroller.getScrollPosition(), false); // Reload entries on navigation
      }
    });
  }

  get currentHmaType() {
    const from = 'search-results.component.ts: get currentHmaType';
    const params = this.searchStateService.getSearchParams(from);
    return params && params.type ? params.type : null;
  }

  get yearModel() {
    const from = 'search-results.component.ts: get yearModel';
    const params = this.searchStateService.getSearchParams(from);
    return params && params.yearmodel ? params.yearmodel : null;
  }

  get screenSize() {
    return this.windowSize.getScreenSize();
  }

  ngOnInit() {
    
    this.resultEntriesSubject.subscribe(entries => console.log("Current entries:", entries));
    
    this.searchStateService.hasMoreResults$.subscribe(value => {
      this.hasMoreResults = value;
    });

    this.searchStateService.searchParams$.subscribe((params) => {
      if (Object.keys(params).length === 0) {
        this.resultEntriesSubject.next([]);
      }
      this.searchParams = params;
    });

    this.showCounter = this.checkIfSearchEmpty();

    

  }

  refreshPage() {
    location.reload();
    //Kanskje vi scroller her og? 
  }

  shouldShowNormalEntry(index: number): boolean {
    if (this.screenSize === 'desktop' || this.screenSize === 'desktop_very_large' || this.screenSize === 'desktop_large') {
      return (index + 1) % 13 !== 0;
    }
    else if (this.screenSize === 'tablet') {
      return (index + 1) % 7 !== 0;
    } 
    else if (this.screenSize === 'mobile') {
      return (index + 1) % 7 !== 0;
    }
    return true;
  }
  

  initializeIntersectionObserver() {
    if (this.observer) {
      this.observer.disconnect();
    } else {
      //console.log('search-results.component.ts: initializeIntersectionObserver : no observer');
    }
    if (this.sentinel) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          //console.log('search-results.component.ts: initializeIntersectionObserver : IntersectionObserver callback, entry:', entry);
          if (entry.isIntersecting) {
            const scrollPosition = this.viewportScroller.getScrollPosition();
            
            this.loading = false;
            this.hasMoreResults = true;
            let pagesLoaded = this.searchStateService.getPagesLoadedBatch();
            if (pagesLoaded && pagesLoaded > 0) {
              this.searchStateService.setStartFromPageZero(false);
            } else {
              this.searchStateService.setStartFromPageZero(true);
            }
            this.loadMore(scrollPosition, false);

            
          }
        });
      }, {
        root: null,
        rootMargin: '-1000px 0px 0px 0px',
        threshold: [0, 0.05, 0.1, 0.25, 0.5, 0.75, 1],
      });
      this.observer.observe(this.sentinel.nativeElement);
     //console.log('search-results.component.ts: initializeIntersectionObserver : Observer attached to sentinel:', this.sentinel.nativeElement);
    } else {
      //  console.log('search-results.component.ts: initializeIntersectionObserver : cannot find sentinel');
    }
  }


  ngAfterViewInit() {
    console.log("/// ngAfterViewInit - setting up observer with ViewChildren");
    const scrollPosition = this.viewportScroller.getScrollPosition();
    this.loadMore(scrollPosition, false);
  }
  

  ngAfterViewChecked(): void {
    if (this.sentinel && this.sentinel.nativeElement !== this.lastObservedSentinel) {
      this.initializeIntersectionObserver();
      this.lastObservedSentinel = this.sentinel.nativeElement;
    }
  }


  checkIfSearchEmpty() {
    const nonSearchParams = ['sort', 'type', 'page'];
    return Object.keys(this.search.args.toParams()).filter((val) => nonSearchParams.indexOf(val) < 0).length > 0;
  }

  private totalCount: number = 0;
  private maintainScrollPosition = false;

loadMore(scrollPosition: any, isButton: boolean): void {
    //document.documentElement.classList.add('stop-scrolling');
    //document.body.classList.add('stop-scrolling');
    if (this.loading || !this.hasMoreResults) {
      return;
    }

    let pagesLoadedBatch = this.searchStateService.getPagesLoadedBatch();

    if (pagesLoadedBatch >= 2) {
      this.showLoadMore = true;
      return;
    }

    this.loading = true;
    this.currentPage++;

    
    this.currentSearchParams = { ...this.searchStateService.getSearchParams('search-results - loadMore') };
    this.cdr.detectChanges();
    const searchArgs = { ...this.currentSearchParams, page: this.currentPage.toString() };

    if (!searchArgs) {
        console.error('searchArgs is not defined');
        this.loading = false;
        return;
    }

    if (this.searchStateService.getStartFromPageZero()) {
        //console.log('search-results.component.ts: loadMore : getStartFromPageZero == true. setting currentpage to 0');
        this.currentPage = 0;
        this.hasMoreResults = true;
        searchArgs.page = this.currentPage.toString();
        this.searchStateService.setStartFromPageZero(false);
    }
    //console.log('search-results.component.ts: loadMore: appendSearchParams: ' + JSON.stringify(searchArgs));
    this.searchStateService.appendSearchParams(searchArgs);
    const newParams = this.searchStateService.getSearchParams('search-results - loadMore 2');

    this.searchStateService.updateResultEntries(true)
      .subscribe(result => {
          if (!result || !result.data || result.data.length === 0) {
              this.hasMoreResults = false;
              this.showLoadMore = false;
              this.loading = false;
              return;
          } else {
              this.hasMoreResults = true;
          }
          

          // Ensure the results are appended correctly
          const updatedEntries = [...this.resultEntriesSubject.value, ...result.data];
          this.resultEntriesSubject.next(updatedEntries);
          this.cdr.detectChanges();
          // Fix batch count update
          //pagesLoadedBatch = this.searchStateService.getPagesLoadedBatch(); // Re-fetch
          pagesLoadedBatch++;
          this.searchStateService.setPagesLoadedBatch(pagesLoadedBatch); // Store correct value

          this.showLoadMore = pagesLoadedBatch >= 2;
          this.hasMoreResults = result.data.length > 0;
          this.cdr.detectChanges();
          this.loading = false;
      },
      error => {
          console.error('Error loading more entries:', error);
          this.loading = false;
      });
      //this.searchStateService.updateUrl();
      if (isButton) {
        console.log('???isButton');
        console.log('???scrollPosition(loadMore): ' + scrollPosition);
        this.searchStateService.scrollToSamePosition(scrollPosition); 
      }
      //setTimeout(() => {
        //document.documentElement.classList.remove('stop-scrolling');
        //document.body.classList.remove('stop-scrolling');
      //}, 5000);
  }

  onLoadMoreButtonClick(): void {
    this.searchStateService.setPagesLoadedBatch(0);
    this.showLoadMore = false;
    //this.maintainScrollPosition = true;
    const scrollPosition = window.scrollY; // || document.documentElement.scrollTop;
    console.log ('???window.scrollY: ' + window.scrollY);
    console.log ('???document.documentElement.scrollTop: ' + document.documentElement.scrollTop);
    console.log('???scrollPosition' + scrollPosition);
    this.loadMore(scrollPosition, true);
  }

  ngOnDestroy() {
    

    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.observer) {
      this.observer.disconnect();
    }
    //this.searchStateService.clearSearchParams();

    this.resultEntriesSubject.next([]);
    
  }
  
}
