import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '../../../global/services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  dropdownOpen = false;
  dropdownOpen2 = false;

  @Input() backLink: { link: any[], text: string };
  isLoggedIn: boolean = false;

  constructor(private authService: AuthenticationService) { }

  toggleDropdown(event: Event): void {
    event.preventDefault(); // Prevent navigation if needed
    this.dropdownOpen = !this.dropdownOpen;
  }

  toggleDropdown2(event: Event): void {
    event.preventDefault(); // Prevent navigation if needed
    this.dropdownOpen2 = !this.dropdownOpen2;
  }

  closeDropdown(): void {
    this.dropdownOpen = false;
  }

  ngOnInit() {
    this.authService.isLoggedIn().subscribe(
      (loggedIn) => {
        this.isLoggedIn = loggedIn;
      },
      (error) => {
        console.error('Error checking login status:', error);
      }
    );
  }
}
