
const types = {};

types['chassis'] = {
  hmaAuto: ['Cabriolet', 'Coupe', 'Flerbruksbil', 'Kasse', 'Kombi (3-dørs)', 'Kombi (5-dørs)', 'Pickup', 'SUV/Offroad', 'Sedan', 'Stasjonsvogn', 'Annet']
};

types['fueltype'] = {
  default: ['Bensin', 'Diesel', 'Gass', 'Elektrisitet', 'Elektrisitet+bensin', 'Elektrisitet+diesel', 'Gass+bensin', 'Gass+diesel'],
  hmaMC: ['Bensin', 'Elektrisitet'],
  hmaBoat: ['Bensin', 'Diesel', 'Gass', 'Elektrisitet', 'Elektrisitet+bensin', 'Elektrisitet+diesel', 'Gass+bensin', 'Gass+diesel', 'Seil', 'Manuell' ],
};

types['boatType'] = {
  hmaBoat: ['Bowrider', 'Cabincruiser', 'Daycruiser', 'Gummibåt/Jolle', 'Pilothouse', 'RIB', 'Seilbåt/Motorseiler', 'Skjærgårdsjeep/Landstedbåt', 'Speedbåt', 'Trebåt/Snekke', 'Vannscooter', 'Yacht', 'Yrkesbåt/Sjark/Skøyte', 'Andre'],
};

types['boatMaterial'] = {
  hmaBoat: ['Aluminium', 'Glassfiber', 'Plast', 'Tre', 'Annet'],
};

types['motorIncluded'] = {
  hmaBoat: ['Ja', 'Nei'],
};

types['motorType'] = {
  hmaBoat: ['Innenbords', 'Utenbords', 'Annet'],
};

types['regClass'] = {
  hmaAuto: ['Personbil', 'Lett lastebil', 'Varebil', 'Kombinertbil', 'Minibuss', 'Buss', 'Annet'],
};

types['colour'] = {
  default: ['Beige', 'Blå', 'Bronse', 'Brun', 'Grønn', 'Grå', 'Gul', 'Gull', 'Hvit', 'Lilla', 'Oransje', 'Rosa', 'Rød', 'Svart', 'Sølv', 'Turkis'],
};

types['category'] = {
  hmaMobileHome: ['Alkove', 'Bybobil', 'Camper', 'Delintegrert', 'Integrert'],
  hmaMC: ['MC', 'Moped', 'ATV', 'Snøscooter'],
  hmaOther: ['Bil', 'Tilhenger', 'MC', 'Bobil', 'Båt', 'Annet'],
};

types['bedTypes'] = {
  hmaMobileHome: ['Dobbeltseng', 'Twin-beds', 'Queen-bed', 'Fransk seng', 'Køyesenger'],
};

types['heatingSystem'] = {
  hmaMobileHome: ['Alde - vannbåren varme', 'Truma - luftbåren varme', 'Truma - varme med el-patron'],
};

types['chassisManufacturer'] = {
  hmaMobileHome: ['Iveco', 'Mercedes', 'Ford', 'Fiat', 'Citroen', 'Peugeot', 'Volkswagen'],
};

types['subCategory'] = {
  hmaMC: {
    'MC': ['Chopper', 'Cruiser', 'Classic/Nakne', 'Cross/Enduro/Trial', 'Custom', 'Lett MC', 'Offroad/Motard', 'Scooter', 'Sidevogn', 'Sport', 'Touring', 'Trike', 'Veteran', 'Andre'],
    'Moped': ['Moped', 'Scooter'],
  },
};

types['transmission'] = {
  default: ['Automat', 'Manuell']
};

types['wheeldrive'] = {
  default:	['Forhjul', 'Bakhjul', '4x4']
};

types['warrantyType'] = {
  hmaAuto: ['Nybilgaranti', 'Bruktbilgaranti', 'Ingen']
};

types['timingBeltChanged'] = {
  hmaAuto: ['Ja', 'Nei', 'Vet ikke', 'Har ikke']
};

types['tyresState'] = {
  default: ['Bra', 'Middels', 'Slitte']
};

types['isNew'] = {
  default: [{ id: true, name: 'Ny' },  { id: false, name: 'Brukt' }]
};

export const editTypes: any = types;
