/*
  There is only one global search-object at a time.
 */
import { Injectable } from '@angular/core';
import {SearchService} from './search.service';
import {Search} from '../classes/search';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {ResourceService} from '../../global/services/resource.service';
import {OtheradsService} from './otherads.service';
import { first } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class CurrSearchService {

  private s: Search;

  constructor(
    private searchSvc: SearchService,
    private resourceSvc : ResourceService,
    private otherAds : OtheradsService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    // When the route is changed, this sometimes indicates that the type of the search should change
    // too. Send an update to the search about the type change if so.
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.s) {
          const params = activatedRoute.snapshot.queryParams;
          if (params.type && params.type) {
            this.s.setType(params.type);
          }
        }
      }
    });
  }

  //
  // searchRoot uses this to reset current-search whenever this route gets activated outside this path.
  // / -> /search : reset
  // /my-pages -> /search : reset
  // /view -> /search : search intact
  //
  resetCurrSearch() {
    this.s = null;
  }

  //
  // Get or create current-search (CurrentSearch is always global)
  //
  get(args = {}) {
    if (!this.s)  {
      this.s = new Search( this.searchSvc, this.resourceSvc, this.otherAds, args);
    }

    return this.s;
  }

  got(): boolean {
    return this.s ? true : false;
  }
}
