import { Component, OnInit, ViewChild, OnChanges, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ProspectEditService, ProspectEditSession } from '../../services/prospect-edit.service';
import { MatStepper } from '@angular/material/stepper';
import { NotificationService } from '../../../global/services/notification.service';
import { Observable ,  Subscription, timer } from 'rxjs';
import { editTypes } from '../../../global/static/edit-types';
import { autocompleteSelectionValidator } from '../../../global/validators/autocomplete-selection.validator';
import { debounce } from 'rxjs/operators';
import { UserService, User } from 'src/app/global/services/user.service';
import { WindowRefService } from '../../../global/services/window-ref.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EditFormService, FieldConfig } from '../../services/edit-form.service';
import { HmaTypeService } from 'src/app/global/services/hma-type.service';
import {ProspectService} from "../../services/prospect.service";

@Component({
  selector: 'app-edit-prospect',
  templateUrl: './edit-prospect.component.html',
  styleUrls: ['./edit-prospect.component.scss']
})
export class EditProspectComponent implements OnInit {
  sessionId: string;
  @ViewChild('stepper') stepper: MatStepper;

  form: FormGroup;
  editTypes;

  session: ProspectEditSession = new ProspectEditSession;
  brandOptions: { id: string, name: string }[] = [];
  bedTypesOptions: Array<{ name: string }> = [];
  modelOptions: { id: string, name: string }[] = [];
  brandMap = {};
  isNew: boolean;

  user: User;

  private lookupErr : boolean;

  constructor(
    private prospectEdit: ProspectEditService,
    private prospectService: ProspectService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private userService: UserService,
    private windowRef: WindowRefService,
    private editForm: EditFormService,
    private hmaTypes: HmaTypeService,
  ) { }

  ngOnInit() {
    
    
    this.activatedRoute.params.subscribe(
      params => {
        this.sessionId = params.sessionId;
        this.lookupErr = false;

        if (params.mode) {
          this.isNew = params.mode != 'edit';
        }

        this.prospectEdit.startEditSession(this.sessionId).subscribe(
          (session) => {
            this.session = session;
            this.setupForms();

            this.form.get('mainAd').get('bedTypes').valueChanges.subscribe(value => {
              console.log("Selected Bed Types:", value);
            });

            this.userService.getCurrentUser().subscribe(
              (user) => {
                if (!this.session.data.cname || this.session.data.cname == '') {
                  this.form.get('sale').get('cname').setValue(user.name);
                }
                if (!this.session.data.caddr || this.session.data.caddr == '') {
                  this.form.get('sale').get('caddr').setValue(user.addr);
                }
                if (!this.session.data.cpostno || this.session.data.cpostno == '') {
                  this.form.get('sale').get('cpostno').setValue(user.postCode);
                }

                this.goToActiveStep();
              }
            );
          }
        );
      }
    );
  }

  private setupForms() {
    this.editForm.setSession(this.session);
    this.form = this.editForm.getForm();
    this.editForm.getAllFieldOptions(true);

    // this.setupAutosaveField(this.form.get('regNo'), 'regNoHide');
    this.setupAutosave('mainAd');
    this.setupAutosave('description');
    this.setupAutosave('report');
    this.setupAutosave('sale');
    this.setupAutosave('bedTypes');
    this.setupAutocompletes();

    // Keep the fields for serviced in sync
    if (this.formIsActive('serviced')) {
      this.form.get('report').get('serviced').valueChanges.subscribe(x=> {
        this.form.get('report').get('serviced').setValue(x, {onlySelf: true, emitEvent: false});
      });
    }

    // When the quality report is checked, make all relevant fields required
    if (this.formIsActive('hasQualityReport')) {
      this.setReportFieldsRequired(this.session.data.hasQualityReport);
      this.form.get('report').get('hasQualityReport').valueChanges.subscribe(
        reportEnabled => this.setReportFieldsRequired(reportEnabled)
      );
    }
  }

  goToActiveStep() {
    if (this.stepper !== undefined && this.session.data.regNo != '') {
      this.stepper.selectedIndex = 1;
    }
  }

  /**
   * Sets up autosaving of values on change. Stores changes with a 1 second delay,
   * and only if the value is valid.
   *
   * @param form The form/form group to set up autosaving for.
   */
  private setupAutosave(formName: string) {
    if (!this.formIsActive(formName)) {
      return;
    }

    const form: AbstractControl = this.form.get(formName);

    for (const field in form['controls']) {
      this.setupAutosaveField(form, field);
    }
  }

  private setupAutosaveField(form: AbstractControl, field: string) {
    console.log("setupAutosaveField triggered for:", field, "with value:", form.get(field).value);

    if (!this.fieldIsActive(field)) {
      return;
    }
  
    form.get(field).valueChanges.pipe(
      debounce(() => timer(1000))
    ).subscribe(value => {
      if (value instanceof Array) {
        value = value.join(',');
      }
  
      if (form.get(field).valid) {
        const data = { [field]: value };

        console.log("Saving data:", data); // Check what is actually being sent
        this.prospectEdit.saveSessionValues(data).subscribe(
          (response) => {
            console.log('Server response:', response); // Add logging to inspect the server response
  
            // Update the form if there are field values returned. Tell of any errors.
            if (Object.keys(response.fields).length > 0) {
              for (const key in response.fields) {
                if (response.fields[key].err !== null) {
                  this.notificationService.errorMessage('Kunne ikke lagre endring: ' + response.fields[key].err);
                } else if (key !== field) {
                  Object.keys(this.form.controls).forEach(formName => {
                    if (this.form.get(formName).get(key)) {
                      this.form.get(formName).get(key).setValue(response.fields[key].val, { emitEvent: false });
                    }
                  });
                }
              }
            }
          },
          (error) => {
            this.notificationService.errorMessage('Autolagring feilet.');
          }
        );
      } else {
        console.log('setupAutosaveField ' + field + ' not valid');
      }
    });

    console.log("Form structure:", this.form.value);
  }

  /**
   * Sets up autocompletes for the brand and model selectors, using ajax.
   */
  private setupAutocompletes() {
    if (!this.fieldIsActive('brand')) {
      return;
    }

    const categoryField = this.editForm.getFormField('category');
    let categoryValue = categoryField ? categoryField.value : null;

    const setupBrand = (category: string | null) => {
      this.prospectEdit.getBrandOptions(this.session.type, category).subscribe(
        (brands) => {
          brands.forEach(brand => {
            this.brandOptions.push({
              id: brand,
              name: brand
            });

            this.brandMap['' + brand] = brand;
          });

          this.form.get('mainAd').get('brand').setValue(this.form.get('mainAd').get('brand').value);
        }
      );
    }

    setupBrand(categoryValue);

    if (categoryField) {
      categoryField.valueChanges.subscribe((value) => setupBrand(value));
    }

    // Update options for model when the brand changes.
    this.form.get('mainAd').get('brand').valueChanges.pipe(
      debounce(() => timer(300))
    ).subscribe(value => {
      this.modelOptions = [];
      categoryValue = categoryField ? categoryField.value : null;

      this.prospectEdit.getModelOptions(this.session.type, this.brandMap[value], categoryValue).subscribe(
        (models) => {
          this.form.get('mainAd').get('brand').setValidators(models.length > 0 ? [Validators.required] : []);

          models.forEach(model => {
            this.modelOptions.push({
              id: model,
              name: model
            });
          });
        }
      );
    });
  }

  autocompleteSearch(term, item) {
    return item.name.toUpperCase().includes(term.toUpperCase());
  }

  /**
   * Fetches information about a car based on the regNo. Also stores the reg number and
   * the received values.
   */
  fetchregNoInfo() {
    const errorMessage = 'Kunne ikke hente info basert på reg.nr. Sjekk at nummeret er skrevet riktig, eventuelt fjern reg.nr. for å fortsette.';
    const regNoValue = this.form.get('regNo').get('regNo').value; //.trim.replace(' ', '');

    console.log( regNoValue );
/*
    if (!regNoValue || regNoValue == '') {
      this.stepper.next();
      return;
    }
*/
    let didOk = true;

    this.prospectEdit.getregNoData(regNoValue, this.sessionId).subscribe(
      (result) => {
        let fieldInfo;
        for (const key of Object.keys(result.fields)) {
          fieldInfo = result.fields[key];

          didOk = !fieldInfo.err;

          console.log( fieldInfo );

          for (const formName of Object.keys(this.form.controls)) {
            if (this.form.get(formName).get([fieldInfo.fieldName]) && !fieldInfo.err) {
              this.form.get(formName).get([fieldInfo.fieldName]).setValue(fieldInfo.val);
            }
          }
        }


        if( didOk ) {
          this.lookupErr = false;
          this.stepper.next();
        } else {
          this.notificationService.errorMessage('Fant ikke kjøretøyopplysninger');
          this.lookupErr = true;
        }
      },

      (error) => {
        this.notificationService.errorMessage(errorMessage, 5000);
      }
    );
  }

  /**
   * Update the required status for quality report inputs, based on if
   * the report is enabled.
   */
  setReportFieldsRequired(reportEnabled: boolean) {
    Object.keys(this.form.get('report')['controls']).forEach(fieldName => {
      if (this.editForm.isRequiredInReport(fieldName)) {
        if (reportEnabled) {
          this.form.get('report').get(fieldName).setValidators([Validators.required]);
        }
        else {
          this.form.get('report').get(fieldName).setErrors(null);
          this.form.get('report').get(fieldName).clearValidators();
        }
      }
    });
  }

  /**
   * Get options for selects based on hmaType.
   */
  get options() {
    return this.editForm.getAllFieldOptions();
  }

  /**
   * Get options for a dynamic field.
   */
  getDynamicFieldOptions(fieldName: string) {
    return this.editForm.getFieldOptions(fieldName);
  }

  /**
   * Get the toal price.
   */
  get totalPrice() {
    const price = parseInt(this.form.get('sale').get('priceEx').value) || 0,
          regPrice = !this.fieldIsActive('regPrice') || this.form.get('sale').get('regPriceExcem').value ? 0 : parseInt(this.form.get('sale').get('regPrice').value) || 0;

    return price + regPrice;
  }

  /**
   * Get the minimum price.
   */
  get minPrice() {
    return this.hmaTypes.getType(this.session.type).minPrice;
  }

  /**
   * Closes the editing session, and takes you to the payment page or the overview,
   * depending on the status.
   */
  finishEditing() {
    if (!this.form.get('sale').valid) {
      this.notificationService.errorMessage('Skjemaet inneholder felter som ikke er utfylt riktig. Felter markert med * er påkrevet.');
      return;
    }

    this.prospectEdit.closeEditSession(this.sessionId).subscribe(
      (result) => {
        if (result.err === null) {
          console.log('no err', result );
          const aditemId = result.aditemId;
          this.prospectService.getProspectStatus(aditemId).subscribe((result : any) => {
            console.log('no err', result )
            const status = result.status;
            if (status === 'deleted' || status === 'inactive' ) {
              this.router.navigate(['/bruker/annonser/rediger', aditemId, 'valg']);
            } else if( status === 'new') {
              this.router.navigate(['/bruker/annonser/rediger', aditemId, 'sms-valider']);
            }
            else {
              this.router.navigate(['/bruker/annonser']);
              this.notificationService.successMessage('Endringer i annonsen har blitt lagret.');
            }
          })
        }
        else {
          if (result.err === 'invalidFields') {
            let message = 'Påkrevde felt er ikke fylt ut riktig: ';
            let fieldNames = [];

            for (let i in result.fields) {
              fieldNames.push(this.editForm.getFieldLabel(i, this.session.type));
            }

            message += fieldNames.join(', ');

            this.notificationService.errorMessage(message, (5000 + (fieldNames.length * 1000)));
          }
        }
      },
      (error: HttpErrorResponse) => {
      }
    );
  }

  /**
   * Open a preview window.
   */
  openPreview() {
    const previewWindow = this.windowRef.nativeWindow.open('/preview/' + this.sessionId);
  }

  /**
   * Go to the next page of the form.
   */
  gotoNextPage(form: FormGroup) {
    if (form.valid) {
      this.stepper.next();
    } else {
      this.notificationService.errorMessage('Skjemaet inneholder felter som ikke er utfylt riktig. Felter markert med * er påkrevet.');
    }
  }

  /**
   * Get the label of a field.
   * @param fieldName
   */
  getFieldLabel(fieldName: string) {
    return this.editForm.getFieldLabel(fieldName, this.session.type);
  }

  /**
   * Get the label/name of a form.
   * @param formName
   */
  getFormLabel(formName: string) {
    return this.editForm.getFormLabel(formName, this.session.type);
  }

  /**
   * Get a validation message checking if the given form is valid.
   */
  formValidationMessage(formName) {
    const form = this.form.get(formName);
    return form.valid || (this.isNew && form.untouched) ? null : 'invalid';
  }

  /**
   * Check the value of a form input field.
   * @param formName
   * @param fieldName
   * @param value
   */
  checkFormValue(formName: string, fieldName: string, value: any) {
    return this.formIsActive(formName) && this.fieldIsActive(fieldName) && this.form.get(formName).get(fieldName).value == value;
  }

  /**
   * Check if a form is active for the current hmaType.
   */
  formIsActive(formName: string) {
    return this.editForm.isFormActive(formName, this.session.type);
  }

  /**
   * Check if a field is active for the current hmaType.
   */
  fieldIsActive(fieldName: string) {
    return this.editForm.isFieldActive(fieldName, this.session.type);
  }

  /**
   * Check if a field is required.
   *
   * @param fieldName
   */
  fieldIsRequired(fieldName: string) {
    return this.editForm.isFieldRequired(fieldName, this.session.type);
  }

  /**
   * Check if there is a previous page to the currently active form.
   */
  hasPreviousPage(formName: string) {
    return this.form && Object.keys(this.form.controls).indexOf(formName) > 0;
  }
}
