import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes} from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//import { FooterComponent } from '../global/comp/footer/footer.component';
import { HeaderComponent } from '../global/comp/header/header.component';
import { DfpAdComponent } from '../global/comp/dfp-ad/dfp-ad.component';
import { FormatPricePipe } from '../global/classes/format-price';
import { BigNumberPipe } from '../global/classes/big-number';

import { InViewportModule } from 'ng-in-viewport';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { NgSelectModule } from '@ng-select/ng-select';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NgStringPipesModule } from 'ngx-pipes';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

import { DynamicImageDirective } from '../global/directives/dynamic-image/dynamic-image.directive';
import { InputPasswordComponent } from '../global/comp/input/input-password/input-password.component';
import { InputSelectComponent } from '../global/comp/input/input-select/input-select.component';
import { InputYesNoComponent } from '../global/comp/input/input-yes-no/input-yes-no.component';
import { InputDateComponent } from '../global/comp/input/input-date/input-date.component';
import { InputOptionalComponent } from '../global/comp/input/input-optional/input-optional.component';
import { InputCheckboxGroupsComponent } from '../global/comp/input/input-checkbox-groups/input-checkbox-groups.component';
import { ConfirmationDialogComponent } from '../global/comp/confirmation-dialog/confirmation-dialog.component';
import { ScrollPosRestoreComponent } from '../global/comp/scroll-pos-restore/scroll-pos-restore.component';
import { SaveSearchModalComponent } from '../search/comp/save-search-modal/save-search-modal.component';
import { WindowRefService } from '../global/services/window-ref.service';
import { ProspectTitlePipe } from '../global/classes/prospect-title';
import { StickyElementDirective } from '../global/directives/sticky-element.directive';
import { environment } from 'src/environments/environment';
import { ExpandDetailsModalComponent } from '../search/comp/view-prospect/expand-details-modal/expand-details-modal.component';
import { ExpandEquipmentModalComponent } from '../search/comp/view-prospect/expand-equipment-modal/expand-equipment-modal.component';
import { ExpandReportModalComponent } from 'src/app/search/comp/view-prospect/expand-report-modal/expand-report-modal.component';
import { ProspectDetailsComponent } from '../search/comp/prospect-details/prospect-details.component';
import { ListArticleComponent } from 'src/app/articles/list-article/list-article.component';
import { ArticleComponent } from 'src/app/articles/article/article.component';
import { ArticleService } from 'src/app/articles/services/article.service';
import { ArticleSliderComponent } from 'src/app/article-slider/article-slider.component';

const routes: Routes = [
  { path: 'articles', component: ListArticleComponent },
  { path: 'articles/:slug', component: ArticleComponent },
];

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatButtonModule,
    MatRadioModule,
    SwiperModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatDialogModule,
    FormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    NgSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    InViewportModule,
    NgStringPipesModule,
    PinchZoomModule,
    NgxTrimDirectiveModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RouterModule.forRoot( routes, {
      enableTracing: true,
      scrollPositionRestoration: 'enabled'
    }),
  ],
  providers: [
    [ArticleService],
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 3000 }
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {hasBackdrop: true}
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.reCaptchaKey } as RecaptchaSettings,
    },
    WindowRefService
  ],
  declarations: [
    //FooterComponent,
    HeaderComponent,
    FormatPricePipe,
    BigNumberPipe,
    ProspectTitlePipe,
    InputPasswordComponent,
    InputSelectComponent,
    InputYesNoComponent,
    InputDateComponent,
    InputCheckboxGroupsComponent,
    InputOptionalComponent,
    DynamicImageDirective,
    ScrollPosRestoreComponent,
    ConfirmationDialogComponent,
    DfpAdComponent,
    StickyElementDirective,
    ExpandDetailsModalComponent,
    ExpandEquipmentModalComponent,
    ExpandReportModalComponent,
    ProspectDetailsComponent,
    ArticleComponent,
    ListArticleComponent,
    ArticleSliderComponent

  ],
  exports: [
    BigNumberPipe,
    DynamicImageDirective,
    ScrollPosRestoreComponent,
    //FooterComponent,
    InputPasswordComponent,
    MatSidenavModule,
    MatButtonModule,
    SwiperModule,
    FormatPricePipe,
    ProspectTitlePipe,
    HeaderComponent,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    InputSelectComponent,
    InputYesNoComponent,
    InputOptionalComponent,
    NgSelectModule,
    MatDatepickerModule,
    InputDateComponent,
    InputCheckboxGroupsComponent,
    MatNativeDateModule,
    DfpAdComponent,
    InViewportModule,
    NgStringPipesModule,
    PinchZoomModule,
    NgxTrimDirectiveModule,
    StickyElementDirective,
    RecaptchaModule,
    RecaptchaFormsModule,
    ExpandDetailsModalComponent,
    ExpandEquipmentModalComponent,
    ProspectDetailsComponent,
    ArticleComponent,
    ListArticleComponent,
    ArticleSliderComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    SaveSearchModalComponent,
    ExpandDetailsModalComponent,
    ExpandEquipmentModalComponent,
    ExpandReportModalComponent,
    ProspectDetailsComponent
  ]
})
export class SharedModule {

}
