import { Component, Input, AfterViewInit, ElementRef, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { WindowSizeService } from '../global/services/window-size.service';
import { SearchStateService } from 'src/app/search/comp/frontpage/search-state.service';
declare var googletag: any;

@Component({
  selector: 'app-ad-slot',
  templateUrl: './ad-slot.component.html'
})
export class AdSlotComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() index!: number;
  @Input() format: string;

  adSlotId = '';
  adFilled: boolean = false;
  veteranYear: number;

  private baseIds = {
    hmaAuto: {
      leaderBoardAdId: 'div-gpt-ad-leaderboard-auto-1727342221927',
      mediumRectangleAdId: 'div-gpt-ad-medium-rectangle-auto-1742213217593',
      mobileSRPAdId: 'div-gpt-ad-mobile-srp-auto-1727342418004',
      skyscraperAdId: 'div-gpt-ad-skyscraper-auto-1727342554949',
      mobileTopAdId: 'div-gpt-ad-mobile-top-auto-1727342487527',
    },
    hmaMobileHome: {
      leaderBoardAdId: 'div-gpt-ad-leaderboard-mobile-home-1727342755759',
      mediumRectangleAdId: 'div-gpt-ad-medium-rectangle-mobile-home-1727342804386',
      mobileSRPAdId: 'div-gpt-ad-mobile-srp-mobile-home-1727342965016',
      skyscraperAdId: 'div-gpt-ad-skyscraper-mobile-home-1727342856869',
      mobileTopAdId: 'div-gpt-ad-mobile-top-mobile-home-1727342904892',
    },
    hmaCamping: {
      leaderBoardAdId: 'div-gpt-ad-leaderboard-camping-1727343363038',
      mediumRectangleAdId: 'div-gpt-ad-medium-rectangle-camping-1727343375663',
      mobileSRPAdId: 'div-gpt-ad-mobile-srp-camping-1727343380892',
      skyscraperAdId: 'div-gpt-ad-skyscraper-camping-1727343391548',
      mobileTopAdId: 'div-gpt-ad-mobile-top-camping-1727343386667',
    },
    hmaMC: {
      leaderBoardAdId: 'div-gpt-ad-leaderboard-mc-1727343760769',
      mediumRectangleAdId: 'div-gpt-ad-medium-rectangle-mc-1727343764990',
      mobileSRPAdId: 'div-gpt-ad-mobile-srp-mc-1727343780494',
      skyscraperAdId: 'div-gpt-ad-skyscraper-mc-1727343805026',
      mobileTopAdId: 'div-gpt-ad-mobile-top-mc-1727343800388',
    },
    hmaVeteran: {
      leaderBoardAdId: 'div-gpt-ad-leaderboard-veteran-1727343811753',
      mediumRectangleAdId: 'div-gpt-ad-medium-rectangle-veteran-1727343816889',
      mobileSRPAdId: 'div-gpt-ad-mobile-srp-veteran-1727343821821',
      skyscraperAdId: 'div-gpt-ad-skyscraper-veteran-1727343835002',
      mobileTopAdId: 'div-gpt-ad-mobile-top-veteran-1727343829550',
    },
    hmaBoat: {
      leaderBoardAdId: 'div-gpt-ad-leaderboard-boat-1727340533094',
      mediumRectangleAdId: 'div-gpt-ad-medium-rectangle-boat-1727340912962',
      mobileSRPAdId: 'div-gpt-ad-mobile-srp-boat-1727341781980',
      skyscraperAdId: 'div-gpt-ad-skyscraper-boat-1727342052559',
      mobileTopAdId: 'div-gpt-ad-mobile-top-boat-1727341965455-0',
    },
    default: {
      leaderBoardAdId: 'div-gpt-ad-leaderboard-front-1733844341493',
      mediumRectangleAdId: 'div-gpt-ad-medium-rectangle-front-1733928726041',
      mobileSRPAdId: 'div-gpt-ad-mobile-srp-front-1733922869884',
      skyscraperAdId: 'div-gpt-ad-skyscraper-front-1733758070661',
      mobileTopAdId: 'div-gpt-ad-mobile-top-front-1733922228999',
    }
  };

  adDivIds = {
    leaderBoardAdId: '',
    mediumRectangleAdId: '',
    mobileSRPAdId: '',
    skyscraperAdId: '',
    mobileTopAdID: '',
  };

  get currentHmaType() {
    const from = 'search-results.component.ts: get currentHmaType';
    const params = this.searchStateService.getSearchParams(from);
    return params && params.type ? params.type : null;
  }

  adSlots: { id: string; path: string; size: number[][] }[] = [];

  constructor(
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private windowSize: WindowSizeService,
    private searchStateService: SearchStateService,
  ) {
    const currentYear = new Date().getFullYear();
    this.veteranYear = currentYear - 30;
  }

  ngOnInit() {
    this.searchStateService.searchParams$.subscribe(() => {
      this.refreshAds();
    });
  }

  refreshAds() {
    if (googletag) {
      googletag.cmd.push(() => {
        googletag.pubads().refresh();
        console.log('AdSlotComponent: Ads refreshed.');
      });
    }
  }

  ngAfterViewInit() {
    const ids = this.baseIds[this.currentHmaType] || this.baseIds.default;
    let selectedAdId = '';

    switch (this.format) {
      case 'leaderboard':
        selectedAdId = ids.leaderBoardAdId;
        break;
      case 'skyscraper':
        selectedAdId = ids.skyscraperAdId;
        break;
      case 'medium_rectangle':
        selectedAdId = ids.mediumRectangleAdId;
        break;
      case 'mobile_top':
        selectedAdId = ids.mobileTopAdId;
        break;
      case 'mobile_srp':
        selectedAdId = ids.mobileSRPAdId;
        break;
      default:
        console.warn("ngAfterViewInit: UNKNOWN FORMAT:", this.format);
        break;
    }

    this.adSlotId = `${selectedAdId}-${this.index}`;
    console.log(`AdSlotComponent: Setting adSlotId to ${this.adSlotId} for index ${this.index}`);

    this.setAdId(this.index);
    this.initializeAdSlot(this.index);

    setTimeout(() => {
      this.loadAd();
    }, 100);
  }

  setAdId(index: number) {
    if (index === undefined || index === null || isNaN(index)) {
      console.error("/// Invalid index provided to setAdIds:", index);
      return;
    }
    const ids = this.baseIds[this.currentHmaType] || this.baseIds.default;
    switch (this.format) {
      case 'leaderboard':
        this.adDivIds.leaderBoardAdId = `${ids.leaderBoardAdId}-${index}`;
        break;
      case 'skyscraper':
        this.adDivIds.skyscraperAdId = `${ids.skyscraperAdId}-${index}`;
        break;
      case 'medium_rectangle':
        this.adDivIds.mediumRectangleAdId = `${ids.mediumRectangleAdId}-${index}`;
        break;
      case 'mobile_top':
        this.adDivIds.mobileTopAdID = `${ids.mobileTopAdID}-${index}`;
        break;
      case 'mobile_srp':
        this.adDivIds.mobileSRPAdId = `${ids.mobileSRP}-${index}`;
        break;
      default:
        console.warn("setAdId: UNKNOWN FORMAT:", this.format);
        break;
    }
    console.log(`/// AdslotCOmponent : Ad Div IDs set:`, this.adDivIds);
  }

  initializeAdSlot(index: number) {
    if (index === undefined || index === null || isNaN(index)) {
      console.error("/// Invalid index provided to initializeAdSlots:", index);
      return;
    }
    console.log("/// AdSlotComponent: initializeAdSlots running for index", index);
    const ids = this.baseIds[this.currentHmaType] || this.baseIds.default;

    switch (this.currentHmaType) {
      case 'hmaAuto':
        switch (this.format) {
          case 'leaderboard':
            this.adSlots.push(
              {
                id: `${ids.leaderBoardAdId}-${index}`,
                path: '/23204533453/biler/biler_leaderboard',
                size: [[980, 300]]
              }
            );
            break;
          case 'skyscraper':
            this.adSlots.push(
              {
                id: `${ids.skyscraperAdId}-${index}`,
                path: '/23204533453/biler/biler_skyscraper',
                size: [[300, 600], [150, 600]]
              }
            );
            break;
          case 'medium_rectangle':
            this.adSlots.push(
              {
                id: `${ids.mediumRectangleAdId}-${index}`,
                path: '/23204533453/biler/biler_medium_rectangle',
                size: [[580, 400], [300, 250]]
              }
            );
            break;
          case 'mobile_srp':
            this.adSlots.push(
              {
                id: `${ids.mobileSRPAdId}-${index}`,
                path: '/23204533453/biler/biler_mobile_srp',
                size: [[300, 250], [320, 250], [320, 320], [320, 400]],
              }
            );
            break;
          case 'mobile_top':
            this.adSlots.push(
              {
                id: `${ids.mobileTopAdId}-${index}`,
                path: '/23204533453/biler/biler_mobile_top',
                size: [[300, 250], [320, 250], [320, 320], [320, 400]],
              },
            );
            break;
          default:
            console.warn('Unrecognized format:', this.format);
            break;
        }
        break;
      case 'hmaMobileHome':
        switch (this.format) {
          case 'leaderboard':
            this.adSlots.push(
              {
                id: `${ids.leaderBoardAdId}-${index}`,
                path: '/23204533453/bobil/bobil_leaderboard',
                size: [[980, 300]]
              }
            );
            break;
          case 'skyscraper':
            this.adSlots.push(
              {
                id: `${ids.skyscraperAdId}-${index}`,
                path: '/23204533453/bobil/bobil_skyscraper',
                size: [[300, 600], [150, 600]]
              }
            );
            break;
          case 'medium_rectangle':
            this.adSlots.push(
              {
                id: `${ids.mediumRectangleAdId}-${index}`,
                path: '/23204533453/bobil/bobil_medium_rectangle',
                size: [[580, 400], [300, 250]]
              },
            );
            break;
          case 'mobile_top':
            this.adSlots.push(
              {
                id: `${ids.mobileTopAdId}-${index}`,
                path: '/23204533453/bobil/bobil_mobile_top',
                size: [[300, 250], [320, 250], [320, 320], [320, 400]],
              },
            );
            break;
          case 'mobile_srp':
            this.adSlots.push(
              {
                id: `${ids.mobileSRPAdId}-${index}`,
                path: '/23204533453/bobil/bobil_mobile_srp',
                size: [[300, 250], [320, 250], [320, 320], [320, 400]],
              },
            );
          default:
            console.warn('Unrecognized format:', this.format);
            break;
        }
        break;
      case 'hmaCamping':
        switch (this.format) {
          case 'leaderboard':
            this.adSlots.push(
              {
                id: `${ids.leaderBoardAdId}-${index}`,
                path: '/23204533453/camping/camping_leaderboard',
                size: [[980, 300]]
              }
            );
            break;
          case 'skyscraper':
            this.adSlots.push(
              {
                id: `${ids.skyscraperAdId}-${index}`,
                path: '/23204533453/camping/camping_skyscraper',
                size: [[300, 600], [150, 600]]
              }
            );
            break;
          case 'medium_rectangle':
            this.adSlots.push(
              {
                id: `${ids.mediumRectangleAdId}-${index}`,
                path: '/23204533453/camping/camping_medium_rectangle',
                size: [[580, 400], [300, 250]]
              }
            );
            break;
          case 'mobile_top':
            this.adSlots.push(
              {
                id: `${ids.mobileTopAdId}-${index}`,
                path: '/23204533453/camping/camping_mobile_top',
                size: [[300, 250], [320, 250], [320, 320], [320, 400]],
              }
            );
            break;
          case 'mobile_srp':
            this.adSlots.push(
              {
                id: `${ids.mobileSRPAdId}-${index}`,
                path: '/23204533453/camping/camping_mobile_srp',
                size: [[300, 250], [320, 250], [320, 320], [320, 400]]
              }
            );
            break;
          default:
            console.warn('/// Unrecognized format:', this.format);
            break;
        }
        break;
      case 'hmaMC':
        switch (this.format) {
          case 'leaderboard':
            this.adSlots.push(
              {
                id: `${ids.leaderBoardAdId}-${index}`,
                path: '/23204533453/mc/mc_leaderboard',
                size: [[980, 300]]
              },
            );
            break;
          case 'skyscraper':
            this.adSlots.push(
              {
                id: `${ids.skyscraperAdId}-${index}`,
                path: '/23204533453/mc/mc_skyscraper',
                size: [[300, 600], [150, 600]]
              }
            );
            break;
          case 'medium_rectangle':
            this.adSlots.push(
              {
                id: `${ids.mediumRectangleAdId}-${index}`,
                path: '/23204533453/mc/mc_medium_rectangle',
                size: [[580, 400], [300, 250]]
              },
            );
            break;
          case 'mobile_top':
            this.adSlots.push(
              {
                id: `${ids.mobileTopAdId}-${index}`,
                path: '/23204533453/mc/mc_mobile_top',
                size: [[300, 250], [320, 250], [320, 320], [320, 400]],
              }
            );
            break;
          case 'mobile_srp':
            this.adSlots.push(
              {
                id: `${ids.mobileSRPAdId}-${index}`,
                path: '/23204533453/mc/mc_mobile_srp',
                size: [[300, 250], [320, 250], [320, 320], [320, 400]]
              }
            );
            break;
          default:
            console.warn('Unrecognized format:', this.format);
            break;
        }
        break;
      case 'hmaVeteran':
        switch (this.format) {
          case 'leaderboard':
            this.adSlots.push(
              {
                id: `${ids.leaderBoardAdId}-${index}`,
                path: '/23204533453/veteranbil/veteranbil_leaderboard',
                size: [[980, 300]]
              }
            );
            break;
          case 'skyscraper':
            this.adSlots.push(
              {
                id: `${ids.skyscraperAdId}-${index}`,
                path: '/23204533453/veteranbil/veteranbil_skyscraper',
                size: [[300, 600], [150, 600]]
              }
            );
            break;
          case 'medium_rectangle':
            this.adSlots.push(
              {
                id: `${ids.mediumRectangleAdId}-${index}`,
                path: '/23204533453/veteranbil/veteranbil_medium_rectangle',
                size: [[580, 400], [300, 250]]
              }
            );
            break;
          case 'mobile_top':
            this.adSlots.push(
              {
                id: `${ids.mobileTopAdId}-${index}`,
                path: '/23204533453/veteranbil/veteranbil_mobile_top',
                size: [[300, 250], [320, 250], [320, 320], [320, 400]],
              }
            );
            break;
          case 'mobile_srp':
            this.adSlots.push(
              {
                id: `${ids.mobileSRPAdId}-${index}`,
                path: '/23204533453/veteranbil/veteranbil_mobile_srp',
                size: [[300, 250], [320, 250], [320, 320], [320, 400]],
              }
            );
            break;
          default:
            console.warn('Unrecognized format:', this.format);
            break;
        }
        break;
      case 'hmaBoat':
        switch (this.format) {
          case 'leaderboard':
            this.adSlots.push(
              {
                id: `${ids.leaderBoardAdId}-${index}`,
                path: '/23204533453/baat/baat_leaderboard',
                size: [[980, 300]]
              }
            );
            break;
          case 'skyscraper':
            this.adSlots.push(
              {
                id: `${ids.skyscraperAdId}-${index}`,
                path: '/23204533453/baat/baat_skyscraper',
                size: [[300, 600], [150, 600]]
              }
            );
            break;
          case 'medium_rectangle':
            this.adSlots.push(
              {
                id: `${ids.mediumRectangleAdId}-${index}`,
                path: '/23204533453/baat/baat_medium_rectangle',
                size: [[580, 400], [300, 250]]
              },
            );
            break;
          case 'mobile_top':
            this.adSlots.push(
              {
                id: `${ids.mobileTopAdId}-${index}`,
                path: '/23204533453/baat/baat_mobile_top',
                size: [[300, 250], [320, 250], [320, 320], [320, 400]],
              }
            );
            break;
          case 'mobile_srp':
            this.adSlots.push(
              {
                id: `${ids.mobileSRPAdId}-${index}`,
                path: '/23204533453/baat/baat_mobile_srp',
                size: [[300, 250], [320, 250], [320, 320], [320, 400]],
              }
            );
            break;
          default:
            console.warn('Unrecognized format:', this.format);
            break;
        }
        break;
      default:
        switch (this.format) {
          case 'leaderboard':
            this.adSlots.push(
              {
                id: `${ids.leaderBoardAdId}-${index}`,
                path: '/23204533453/front/front_leaderboard',
                size: [[980, 300]]
              }
            );
            break;
          case 'skyscraper':
            this.adSlots.push(
              {
                id: `${ids.skyscraperAdId}-${index}`,
                path: '/23204533453/front/front_skyscraper',
                size: [[300, 600], [150, 600]]
              }
            );
            break;
          case 'medium_rectangle':
            this.adSlots.push(
              {
                id: `${ids.mediumRectangleAdId}-${index}`,
                path: '/23204533453/front/front_medium_rectangle',
                size: [[580, 400], [300, 250]]
              }
            );
            break;
          case 'mobile_top':
            this.adSlots.push(
              {
                id: `${ids.mobileTopAdId}-${index}`,
                path: '/23204533453/front/front_mobile_top',
                size: [[320, 250], [300, 250], [320, 320], [320, 400]],
              }
            );
            break;
          case 'mobile_srp':
            this.adSlots.push(
              {
                id: `${ids.mobileSRPAdId}-${index}`,
                path: '/23204533453/biler/biler_mobile_srp',
                size: [[300, 250], [320, 250], [320, 320], [320, 400]]
              }
            );
            break;
          default:
            console.warn('Unrecognized format:', this.format);
            break;
        }
        break;
    }

    console.log("/// AdSlotComponent: Ad Slots:", this.adSlots);
  }

  loadAd() {
    if (!googletag) return;

    if (typeof googletag !== 'undefined' && googletag.cmd) {
      googletag.cmd.push(() => {
        const adElement = document.getElementById(this.adSlotId);
        if (!adElement) {
          console.error(`AdSlotComponent: Cannot find div with id ${this.adSlotId}. Delaying execution.`);
          setTimeout(() => this.loadAd(), 100);
          return;
        }

        const existingSlot = googletag.pubads().getSlots().find(s => s.getSlotElementId() === this.adSlotId);
        if (!existingSlot) {
          console.log(`AdSlotComponent: Defining ad slot for ${this.adSlotId}`);
          if (this.adSlots.length > 0) {
            googletag.defineSlot(this.adSlots[0].path, this.adSlots[0].size, this.adSlotId)
              .addService(googletag.pubads());
          }
        } else {
          console.log(`AdSlotComponent: Ad slot ${this.adSlotId} is already defined.`);
        }

        googletag.pubads().enableSingleRequest();
        //googletag.pubads().collapseEmptyDivs();
        googletag.enableServices();
        console.log(`AdSlotComponent: Displaying ad slot ${this.adSlotId}`);
        googletag.display(this.adSlotId);

      });
    } else {
      console.error("AdSlotComponent: Google Publisher Tag is not available.");
    }
  }

  ngOnDestroy() {
    this.destroyAd();
  }

  destroyAd() {
    if (googletag) {
      googletag.cmd.push(function() {
        googletag.destroySlots();
      });
    }
  }
}
