import {Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy,  ViewChild, EventEmitter, Output, SimpleChanges} from '@angular/core';
import { CommonModule } from '@angular/common';
import {SearchArgMultiVal, ISearchArgMultiValNode} from '../../../classes/search-args';
import {ResourceService} from '../../../../global/services/resource.service';
import { ActivatedRoute } from '@angular/router';
import { skip } from 'rxjs/operators';
import { Subscription } from "rxjs";
import { SearchStateService } from 'src/app/search/comp/frontpage/search-state.service';
import { multival } from 'src/app/global/static/multi-val-data';

@Component({
  selector: 'app-inp-multi-val',
  templateUrl: './inp-multi-val.component.html',
  styleUrls: ['./inp-multi-val.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InpMultiValComponent implements OnInit, OnDestroy, CommonModule {
  
  listeners: Subscription[] = [];
  @Input() key: string;
  @Input() arg: SearchArgMultiVal;
  @Input() label: string;
  @Input() popularFilter = false;
  @Input() showInput = false;

  @Output() valueChange = new EventEmitter<{ value: string }>();

  showUnpopular = false;

  nodes: ISearchArgMultiValNode[];
  selectedValues: any[];

  inputsLoaded = false;
  model: any[];

  constructor (
    private resSvc: ResourceService,
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private searchStateService: SearchStateService
  ) { }

  get currentHmaType() {
    const from = 'search-view.component.ts: get currentHmaType';
    const params = this.searchStateService.getSearchParams(from);
    return params && params.type ? params.type : null;
  }

  get availableNodes() {
    const nodes = !this.popularFilter || this.showUnpopular || !this.nodes ? this.nodes : this.nodes.filter(
      item => item.popular || item.selected
    );

    if (!nodes) {
      return [];
    }

    return nodes;
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log(' ngOnChanges() triggered in InpMultiValComponent', changes);
    this.arg.onTypeChange(this.currentHmaType);

    if (changes['arg']) {
      //console.log(' arg changed:', changes['arg'].currentValue);
      this.updateNodes(); // Ensure we update nodes when inputs change
    }
  }
  

  ngOnInit() {
    //console.log(' ngOnInit() in InpMultiValComponent');

    this.arg.onTypeChange(this.currentHmaType)
    //this.activatedRoute.params.subscribe(params => {

    this.searchStateService.searchParams$.subscribe(params => {
      this.updateNodes();
      this.arg.onTypeChange(this.currentHmaType)
  });
  

    // Initial load
    this.updateNodes();

    // Listen for node changes
    let listener = this.arg.observeNodeChanges().subscribe(
      (nodes) => {
        //console.log(' observeNodeChanges() fired! Nodes:', nodes);
        this.updateNodes();
      },
      (error) => console.error('observeNodeChanges() error:', error)
    );

    this.listeners.push(listener);
    //console.log('Subscribed to observeNodeChanges()');

    // Listen for route param changes expiriment... subscribe to searchParams$ here aswell... 
    //istener = this.activatedRoute.queryParams.pipe(skip(1)).subscribe(
      listener = this.searchStateService.searchParams$.pipe(skip(1)).subscribe(
      (params) => {
        //console.log('Query params changed:', params);
        this.updateNodes();
      },
      (error) => console.error('🚨 Query params subscription error:', error)
    );

    this.listeners.push(listener);
    //console.log('Subscribed to queryParams');

    //  fjerne checkboxes hvis parameterne ikke er der lenger ( sletta med knapp)
    this.searchStateService.searchParams$.subscribe(params => {
      // If the parameter for this input is missing, clear its local selection:
      if (!params[this.arg.name]) {
        // Clear the selection on your nodes:
        if (this.nodes) {
          this.nodes.forEach(node => {
            node.selected = false;
            if (node.children) {
              node.children.forEach(child => child.selected = false);
            }
          });
        }
        // Optionally, update the local model value if you use one
        this.arg.val = '';
        // Force change detection to update the UI:
        this.changeDetector.markForCheck();
      }
    });
  }

  updateNodes() {
    //console.log(' updateNodes() Called!');

    if (!this.arg) {
        console.error('❌ this.arg is undefined!');
        return;
    }

    console.log('Checking if getNodes() Observable emits values...');
      this.arg.getNodes().subscribe(
        (nodes) => {
          console.log('getNodes() returned args:', this.arg);
          console.log('getNodes() returned nodes:', nodes);

          if (!nodes || nodes.length === 0) {
              console.warn('getNodes - No nodes received! Something might be wrong. - nodes_ ' + nodes);
          }

          this.nodes = nodes;

          //console.log(' Updating `nodes`:', this.nodes);

          if (this.showInput && !this.inputsLoaded) {
              //console.log('Setting `inputsLoaded` to true.');
              this.inputsLoaded = true;
          } else {
              //console.log(' `inputsLoaded` already true or showInput is false.');
          }

          this.selectedValues = [];

          nodes.filter(node => node.selected).forEach(node => {
              //console.log(' Selected node:', node);

              let selectedNode: any = {
                  name: node.name,
                  children: node.children ? node.children.filter(child => child.selected) : [],
              };

              selectedNode.childList = selectedNode.children.length === 0
                  ? ''
                  : '(' + selectedNode.children.map(c => c.name).join(', ') + ')';

              this.selectedValues.push(selectedNode);
          });

          //console.log('Final selected values:', this.selectedValues);

          // Force UI to update
          this.changeDetector.markForCheck();
          //console.log('changeDetector.markForCheck() triggered!');
        },
        (error) => {
          console.error('🚨 getNodes() failed with error:', error);
        }
      );
  }

  

  onChange(checked: boolean, argVal: string, childVal: string | null) {
    this.arg.setVal(argVal, childVal, checked);
    //console.log('argVal:', argVal);
    //console.log('childVal:', childVal);

    // Extract all selected values (both parent & child)
    const selectedValues = this.nodes
      .filter(node => node.selected || (node.children && node.children.some(child => child.selected)))
      .map(node => {
        const parentValue = node.selected ? [node.name] : [];
        const childValues = node.children ? node.children.filter(child => child.selected).map(child => child.name) : [];
        return [...parentValue, ...childValues]; 
      })
      .reduce((acc, val) => acc.concat(val), []); // Flatten the array


    // Parent and children
    if (this.arg.name === 'b' || this.arg.name === 'fylke' || this.arg.name === 'category') {
      //console.log('Processing:', this.arg.name);
    
      const groupedValues: Record<string, string[]> = {};
    
      if (this.arg.name === 'b') {
        //console.log('fixBrands: Processing brands and models.');
    
        const knownBrands = new Set([
          ...Object.keys(multival.b.hmaAuto),
          ...Object.keys(multival.b.hmaBoat),
          ...Object.keys(multival.b.hmaMC),
          ...Object.keys(multival.b.hmaMobileHome),
          ...Object.keys(multival.b.hmaCamping)
        ]);
    
        let currentBrand: string | null = null;
    
        selectedValues.forEach(value => {
          if (knownBrands.has(value)) {
            currentBrand = value;
            if (!groupedValues[currentBrand]) {
              groupedValues[currentBrand] = [];
            }
          } else if (currentBrand) {
            groupedValues[currentBrand].push(value);
          }
        });
      }
    
      if (this.arg.name === 'fylke') {
        //console.log('fixFylke: Processing locations.');
    
        const locationHierarchy = {
          Oslo: ['Oslo'],
          Sørlandet: ['Agder'],
          Østlandet: ['Akershus', 'Buskerud', 'Innlandet', 'Telemark', 'Vestfold', 'Østfold'],
          Vestlandet: ['Møre og Romsdal', 'Rogaland', 'Vestland'],
          Trøndelag: ['Trøndelag'],
          'Nord-Norge': ['Nordland', 'Troms', 'Finnmark']
        };
    
        selectedValues.forEach(value => {
          if (locationHierarchy[value]) {
            if (!groupedValues[value]) {
              groupedValues[value] = [];
            }
          } else {
            const parentLocation = Object.keys(locationHierarchy).find(parent =>
              locationHierarchy[parent].includes(value)
            );
    
            if (parentLocation) {
              if (!groupedValues[parentLocation]) {
                groupedValues[parentLocation] = [];
              }
              groupedValues[parentLocation].push(value);
            }
          }
        });
      }
    
      if (this.arg.name === 'category') {
        //console.log('fixCategory: Processing categories.');
    
        const categoryHierarchy = {
          Alkove: [],
          Bybobil: [],
          Camper: [],
          Delintegrert: [],
          Integrert: [],
          MC: ['Chopper', 'Cruiser', 'Classic/Nakne', 'Cross/Enduro/Trial', 'Custom', 'Lett MC', 'Offroad/Motard', 'Sidevogn', 'Sport', 'Touring', 'Trike', 'Veteran', 'Andre'],
          Moped: ['Moped', 'Scooter'],
          ATV: [],
          Snøscooter: []
        };
    
        selectedValues.forEach(value => {
          if (categoryHierarchy[value]) {
            if (!groupedValues[value]) {
              groupedValues[value] = [];
            }
          } else {
            const parentCategory = Object.keys(categoryHierarchy).find(parent =>
              categoryHierarchy[parent].includes(value)
            );
    
            if (parentCategory) {
              if (!groupedValues[parentCategory]) {
                groupedValues[parentCategory] = [];
              }
              groupedValues[parentCategory].push(value);
            }
          }
        });
    
        // Ensure parent 'Moped' appears only once when selected
        if (selectedValues.includes('Moped')) {
          if (!groupedValues['Moped']) {
            groupedValues['Moped'] = [];
          }
          
          const mopedChildSelected = selectedValues.filter(v => v === 'Moped').length > 1;
        
          if (mopedChildSelected) {
            groupedValues['Moped'].push('Moped');
          }
        }        
      }
    
      //console.log('Final Grouped Values:', JSON.stringify(groupedValues, null, 2));
    
      // Format Output
      const formattedString = Object.entries(groupedValues)
        .map(([parent, children]) => {
          return children.length > 0 ? `${parent}_${children.join('_')}` : parent;
        })
        .join(',');
    
      // Emit the formatted string
      this.valueChange.emit({ value: formattedString || null });
    
      //console.log('Final Formatted Output:', formattedString);
    } else {
      // Emit comma-separated string (e.g., "Leather interior, Heated steering wheel")
      this.valueChange.emit({ value: selectedValues.length > 0 ? selectedValues.join(',') : null });
    }

    this.updateNodes();
}

  toggleShowInputs() {
    this.showInput = !this.showInput;

    if (this.showInput && !this.inputsLoaded) {
      this.inputsLoaded = true;
    }
  }

  ngOnDestroy() {
    this.listeners.forEach(listener => listener.unsubscribe());
  }
}



