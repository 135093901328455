import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, subscribeOn, tap, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { UserService } from '../../global/services/user.service';
import { IProspect, IImage, ImageList } from './prospect.service';

@Injectable({
  providedIn: 'root'
})
export class ProspectEditService {
  currentSession: string;

  constructor(private http: HttpClient, private userService: UserService) {
  }

  // Publish ad
  publishAd(adId: number): Observable<any> {
    return this.http.post(`${environment.apiUrl}/e/edit/publish?id=${adId}`, {});
  }

  /**
   * Get all unfinished edit sessions for the current user.
   */
  getProspectSessions(): Observable<ProspectSession> {
    return <Observable<ProspectSession>>this.http.get(`${environment.apiUrl}/e/edit/getsessions`);
  }
  /*
  getBedTypeOptions(): Observable<any[]> {
    // Return an observable of the hardcoded strings
    const hardcodedOptions = [
      { name: 'Single Bed' },
      { name: 'Double Bed' },
      { name: 'Queen Bed' },
      { name: 'King Bed' },
      { name: 'Bunk Bed' }
    ];
  
    return of(hardcodedOptions);
  }
    */

  /**
   * Get options for the brand selector.
   *
   * @param hmaType
   */
  getBrandOptions(hmaType: string, category?: string): Observable<any> {
    return <Observable<any>>this.http.get(`${environment.apiUrl}/a/resources/brands`, { params: { t: hmaType, c: category }});
  }

  /**
   * Get options for the model selector, based on brand.
   *
   * @param hmaType Prospect type.
   * @param brand The brand to search for models for.
   */
  getModelOptions(hmaType: string, brand: string, category?: string): Observable<any> {
    const params = {
      t: hmaType,
      b: brand,
      q: '',
      c: category
    };

    return <Observable<any>>this.http.get(`${environment.apiUrl}/a/resources/models`, { params: params});
  }

  /**
   * Create a new edit session.
   *
   * @param hmaType
   */
  createSession(hmaType: string): Observable<ProspectEditSession> {
    const result = <Observable<any>>this.http.post(`${environment.apiUrl}/e/edit/setupsession`, { type: hmaType });

    result.pipe(
      tap((session) => { this.currentSession = session.esid })
    );

    return result;
  }

  /**
   * Get an edit session for an existing prospect.
   *
   * @param aditemId
   */
  getEditSession(aditemId: number): Observable<ProspectEditSession> {
    const result = <Observable<any>>this.http.post(`${environment.apiUrl}/e/edit/setupsession`, { adid: aditemId });

    result.pipe(
      tap((session) => { this.currentSession = session.esid })
    );

    return result;
  }

  /**
   * Resume a previous edit session.
   *
   * @param sessionId
   */
  startEditSession(sessionId: string): Observable<ProspectEditSession> {
    this.currentSession = sessionId;
    return <Observable<any>>this.http.post(`${environment.apiUrl}/e/edit/setupsession`, { esid: sessionId });
  }

  /**
   * Delete an edit session permanently.
   *
   * @param sessionId
   */
  closeEditSession(sessionId: string): Observable<any> {
    const result = <Observable<any>>this.http.post(`${environment.apiUrl}/e/edit/saveandclose`, { esid: sessionId });

    result.pipe(
      tap((session) => { this.currentSession = null })
    );

    return result;
  }

  /**
   * Delete an edit session.
   *
   * @param sessionId
   */
  deleteEditSession(sessionId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/e/edit/delsession?id=${sessionId}`);
  }

  /**
   * Close an edit session and permanently save changes.
   *
   * @param data
   */
  saveSessionValues(data: { [id: string]: string | number }): Observable<any> {
    const params = {
      data: data,
      esid: this.currentSession
    };

    return <Observable<any>>this.http.post(`${environment.apiUrl}/e/edit/setandvalidate`, params);
  }

  /**
   * Receive information for a prospect based on reg. number.
   *
   * @param regNo
   * @param sessionId
   */
  getregNoData(regNo, sessionId): Observable<any> {

    regNo = (regNo || '').trim().replace(' ', '');

    const params = {
      data: {
      },
      esid: sessionId
    };

    console.log( "regno.length", regNo.length );

    if( regNo.length <= 'AB12345'.length )
      params.data['regNo'] = regNo;
    else
      params.data['regNoVIN'] = regNo;

    return <Observable<any>>this.http.post(`${environment.apiUrl}/e/edit/setandvalidate`, params);
  }

  /**
   * Get the upload URL to be used for uploading images.
   */
  getUploadUrl(): string {
    return `${environment.apiUrl}/e/edit/uploadimg`;
  }

  /**
   * Verify that an upload has worked.
   *
   * @param params
   */
  verifyUpload(params): Observable<any> {
    return <Observable<any>>this.http.post(`${environment.apiUrl}/e/edit/uploadVerify`, params);
  }

  /**
   * Update the text/description for an image.
   *
   * @param imageId
   * @param text
   */
  setImageText(imageId: number, text: string): Observable<ImageList> {
    const params = {
      id: imageId,
      txt: text,
      esid: this.currentSession
    };

    return <Observable<ImageList>>this.http.post(`${environment.apiUrl}/e/edit/imgtxt`, params);
  }

  /**
   * Delete an image permanently.
   *
   * @param imageId
   */
  deleteImage(imageId: number): Observable<ImageList> {
    const params = {
      id: imageId,
      esid: this.currentSession
    };

    return <Observable<ImageList>>this.http.post(`${environment.apiUrl}/e/edit/imgdelete`, params);
  }

  /**
   * Move the position of a prospect's image in the image list.
   *
   * @param imageId
   * @param newPosition
   */
  moveImage(imageId: number, newPosition: number): Observable<ImageList> {
    const params = {
      id: imageId,
      esid: this.currentSession,
      newPos: newPosition
    };

    return <Observable<ImageList>>this.http.post(`${environment.apiUrl}/e/edit/imgmove`, params);
  }

  /**
   * Rotate an image by 90 degrees.
   *
   * @param imageId
   */
  rotateImage(imageId: number): Observable<ImageList> {
    const params = {
      id: imageId,
      esid: this.currentSession,
      dir: 'right'
    };

    return <Observable<ImageList>>this.http.post(`${environment.apiUrl}/e/edit/imgrotate`, params);
  }
}

export interface ProspectSession {
  adid:       number;
  date:       Date;
  desc:       string;
  esid:       string;
  prettyType: string;
  thumb:      number;
  type:       string;
}

export class ProspectEditSession {
  type:       string;
  prettyType: string;
  changed:    Date;
  data:       any = {};
  esid:       string;
}

interface IContact {
  addr: string;
  city: string;
  county: string;
  mobile: string;
  name: string;
  phoneNo: string;
  place: string;
  postNo: string;
}
