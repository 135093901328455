import { Component, OnInit, OnDestroy, ChangeDetectorRef, Renderer2, HostListener  } from '@angular/core';
import { formatDate } from '@angular/common';
import { ViewService } from '../../services/view.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Search } from '../../classes/search';
import { CurrSearchService } from '../../services/curr-search.service';
import { RelatedContentService } from '../../services/related-content.service';
import { IviewData, IprospectData, IRelatedProspectData } from '../../services/view.service';
import { log } from 'util';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NotificationService } from '../../../global/services/notification.service';
import { DialogService } from '../../../global/services/dialog.service';
import { SavedProspectsService } from '../../../global/services/saved-prospects.service';
import { Observable } from 'rxjs';
import { OtheradsService, IOtherAd } from '../../services/otherads.service';
import { AuthenticationService } from '../../../global/services/authentication.service';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { WindowSizeService } from '../../../global/services/window-size.service';
import { MetaTagsService } from 'src/app/global/services/metatags.service';
import { LinkpulseService } from 'src/app/global/services/linkpulse.service';
import { ExpandDetailsModalComponent } from 'src/app/search/comp/view-prospect/expand-details-modal/expand-details-modal.component';
import { ExpandEquipmentModalComponent } from 'src/app/search/comp/view-prospect/expand-equipment-modal/expand-equipment-modal.component';
import { ExpandReportModalComponent } from 'src/app/search/comp/view-prospect/expand-report-modal/expand-report-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare let gtag: Function;
declare var googletag: any;  // Ensure googletag is available

@Component({
  selector: 'app-view-prospect',
  templateUrl: './view-prospect.component.html',
  styleUrls: ['./view-prospect.component.scss'],
})

export class ViewProspectComponent implements OnInit, OnDestroy {
  numberOfItems: number;
  id: number;
  data: IprospectData;
  related$: Observable<IRelatedProspectData[]> | Observable<IOtherAd[]>;
  images: Array<object> = [];
  currentImage: number = 0;
  typeDetails: Array<object> = [];
  salesDetails: Array<object> = [];
  keyInfo: Array<object> = [];
  fullAddress: string;
  saved = false;
  preview = false;
  fullscreenView = false;
  highlightClass: string = '';
  financeLink: string;
  nextLink: string;
  prevLink: string;
  reportLink: Array<string|number> = [];
  prospectTitle: string;
  currentUrl: string;
  veteranYear: number; 
  adSlots: { id: string; path: string; size: any[] }[] = [];
  relatedTests$: Observable<any[]>;
  relatedInfoPage$: Observable<any>;

  constructor(
    private route: ActivatedRoute,
    private vs: ViewService,
    private cs: CurrSearchService,
    private router: Router,
    public ngxSmartModalService: NgxSmartModalService,
    private notifications: NotificationService,
    private savedProspectsService: SavedProspectsService,
    private windowSize: WindowSizeService,
    private authenticationService: AuthenticationService,
    private dialogService: DialogService,
    private relatedContent: RelatedContentService,
    private metatags: MetaTagsService,
    private linkpulse: LinkpulseService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2, 
    
  ) {
    const currentYear = new Date().getFullYear();
    this.veteranYear = currentYear - 30;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const holeDiv = document.querySelector('.hole');
    if (holeDiv) {
      this.renderer.addClass(holeDiv, 'hide');
    }
  }

  get screenSize() {
    return this.windowSize.getScreenSize();
  }

  openDetailsModal(): void {
    const dialogRef = this.dialog.open(ExpandDetailsModalComponent, {
      data: {
        typeDetails: this.typeDetails,
        salesDetails: this.salesDetails
      },
    });
    console.log('this.data.typedata.chassisManufacturer: ' + this.data.typedata.chassisManufacturer);
    console.log('this.data.typedata.heatingSystem: ' + this.data.typedata.heatingSystem);
    console.log('this.data.typedata.bedTypes: ' + this.data.typedata.bedTypes);
  }

  openEquipmentModal() {
    const dialogRef = this.dialog.open(ExpandEquipmentModalComponent, {
      data: { 
        equipment: this.data.equipment,
        equipmentCustom: this.data.equipmentCustom
      }
    });
  }

  openReportModal() {
    this.dialog.open(ExpandReportModalComponent, {
      data: { id: this.id }
    });
  }


  ngOnInit() {
    
    this.currentUrl = window.location.href;

    

    this.route.url.subscribe(urlSegments => {

    const fullPath = urlSegments.map(segment => segment.path).join('/');

      if (fullPath.endsWith('highlightshare')) {
        this.highlightClass = 'highlight-share';
      }
    });

    this.route.params.subscribe( params => {
      if (params.id)
      {
        this.id = parseInt(params.id, 10);

        // Checks if the current prospect is saved for the user
        this.savedProspectsService.getSavedProspects().subscribe((prospects) => {
          this.saved = prospects.find(i => i.aditemid === this.id) !== undefined;
        });

        // Gets the prospect data from the view data service
        return this.vs.getViewData(this.id).subscribe(
          (val) => {
            this.data = val.adData;
            this.cdr.detectChanges();
            this.setupProspectData();
            this.setGoogleMapsAddress();
            this.nextLink = val.nextLink;
            this.prevLink = val.prevLink;
            this.reportLink = ['/view', this.id, 'report'];

            // Setting up metadata and sharing image
            const description = `Pris: ${this.data.price},- Km.stand: ${this.data.typedata.mileage}`;
            this.metatags.setTagValues({
              'og:description': description,
              'description': description
            });
            this.metatags.setImageById(this.data.mainImageId);
            this.metatags.setTitle(this.prospectTitle);
            this.metatags.addMetaTags();
            this.linkpulse.trackProspectView(this.data);
            this.generateFinanceLink();


            if (window['strossle']) {
              window['strossle']('e3e6939b-906c-4f1e-94aa-76ccbc8c0a1c', '.strossle-widget');
            } else {
              console.error('no strøssle!');
            }

          },
          (error) => {
            this.notifications.errorMessage('Kunne ikke finne annonsen.', false);
          });
      }
      else if (params.sessionId) {
        this.preview = true;

        return this.vs.getPreviewData(params.sessionId).subscribe(
          (val) => {
            this.data = val.adData;
            this.cdr.detectChanges();
            this.setupProspectData();
            this.setGoogleMapsAddress();
            this.reportLink = ['/preview', params.sessionId, 'report'];
            this.metatags.setTitle('Forhåndsvisning av annonse');
            this.metatags.addMetaTags();
          },
          (error) => {
            this.notifications.errorMessage('Kunne ikke laste forhåndsvisningen.', false);
          });
      }
    });

    
  }

  setGoogleMapsAddress(): void {
    if (this.data && this.data.contact) {
      this.fullAddress = `${this.data.contact.addr}, ${this.data.contact.postNo}, ${this.data.contact.place}`;

    } else {
      //console.log('No data or contact');
      console.error('Data or contact is undefined');
    }
  }

  isTitleLong(): boolean {
    return this.prospectTitle && this.prospectTitle.length > 40;
  }

  trackPhoneClick(type: string) {
    gtag('event', 'phone', {
      'event_category': 'Phone',
      'event_label': type,
      'transport_type': 'beacon'
    });
  }

  trackNordeaClick(type: string) {
    gtag('event', 'nordea-click', {
      'event_category': 'Nordea-click',
      'event_label': type,
      'transport_type': 'beacon'
    });
  }

  trackOpenSendMessageForm(platform: string) {
    gtag('event', 'open-send-message-form', {
      'event_category': 'Social',
      'event_label': platform,
      'transport_type': 'beacon'
    });
  }

  trackShare(platform: string) {
    gtag('event', 'share', {
      'event_category': 'Social',
      'event_label': platform,
      'transport_type': 'beacon'
    });
  }
  
  shareOnFacebook() {
    let sanitizedUrl = this.currentUrl;
  
    // Remove /highlightshare if present
    if (sanitizedUrl.endsWith('/highlightshare')) {
      sanitizedUrl = sanitizedUrl.replace(/\/highlightshare$/, '');
    }
  
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(sanitizedUrl)}`;
    
    window.open(facebookShareUrl, '_blank');
    this.trackShare('Facebook');
  }

  shareByEmail() {
    // Remove /highlightshare if present
    let sanitizedUrl = this.currentUrl;
    if (sanitizedUrl.endsWith('/highlightshare')) {
        sanitizedUrl = sanitizedUrl.replace(/\/highlightshare$/, '');
    }

    const subject = encodeURIComponent(`Jeg ønsker å dele denne AutoDB-annonsen med deg: ${this.prospectTitle}`);
    const body = encodeURIComponent(
        `Jeg ønsker å dele dette kjøretøyet fra AutoDB med deg:\n\n` +
        `Modell: ${this.prospectTitle}\n` +
        `Totalpris: ${this.data.price}\n` +
        `Kilometerstand: ${this.data.typedata.mileage}\n` +
        `Drivstoff: ${this.data.typedata.fueltype}\n` +
        `Gir: ${this.data.typedata.transmission}\n\n` +
        `Du kan lese hele annonsen her: ${sanitizedUrl}`
    );
    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;

    window.location.href = mailtoLink;
    this.trackShare('Email');
}

  generateFinanceLink() {
    let campaignProduct: string;

    if (this.data.type == 'hmaAuto') {
      campaignProduct = 'bil';
    } else if (this.data.type == 'hmaMC') {
      campaignProduct = 'mc';
    } else if (this.data.type == 'hmaMobileHome' || this.data.type == 'hmaCamping') {
      campaignProduct = 'caravan';
    } else if (this.data.type == 'hmaBoat') {
      campaignProduct = 'Båt';
    }

    const campaignSource = 'autodb'; 
    const campaignCode = 84000900;
    const campaignPrice = Math.round(this.data.price);  
    const campaignEquity = Math.round(campaignPrice * 0.35); // Egenkapital
    const campaignYearModel = this.data.typedata.yearmodel; 
    const campaignRegNumber = this.data.typedata.regNo; 
    const campaignLoanAmount = Math.round(campaignPrice * 0.65); // Resterende
    const campaignPaymentTime = 5; 
    const campaignModel = encodeURIComponent(this.data.typedata.model); // URI Encode to ensure working link
    const campaignBrand = encodeURIComponent(this.data.typedata.brand); // URI Encode to ensure working link
    const campaignMilage = this.data.typedata.mileage;

    this.financeLink = `https://billan.nordea.no/soknad/?produkt=${campaignProduct}&kilde=${campaignSource}&kampanjekode=${campaignCode}&bilpris=${campaignPrice}&egenkapital=${campaignEquity}&aarsmodell=${campaignYearModel}&regnr=${campaignRegNumber}&laanesum=${campaignLoanAmount}&nedbetalingstid=${campaignPaymentTime}&modell=${campaignModel}&merke=${campaignBrand}&km=${campaignMilage}&/skjema/start#/skjema/start`;
  };


/* bil / MC / caravan */
/*
*/


  printPage() {
    window.print();
    this.trackShare('Print');
  }


  setupProspectData() {
    if (this.data.type != 'hmaOther') {
      this.relatedTests$ = this.relatedContent.getTests(this.data);
    }

    this.relatedInfoPage$ = this.relatedContent.getModelInfo(this.data);
    this.images = this.data.images.filter(img => img && img.id != null);
    this.related$ = this.vs.getRelated(this.data);

    let prospectTitle = '';

    const yearmodel = this.data.typedata.yearmodel ? this.data.typedata.yearmodel : '';
    const brand = this.data.typedata.brand ? this.data.typedata.brand : '';
    const model = this.data.typedata.model ? this.data.typedata.model : '';

    if (yearmodel) {
      prospectTitle += yearmodel + ' ';
    }
    if (brand) {
      prospectTitle += brand + ' ';
    }
    if (model) {
      prospectTitle += model;
    }

    prospectTitle = prospectTitle.trim();

    this.prospectTitle = prospectTitle;

    this.setupAllDetails(); //  
    //this.setAdIds(); // OLD BUT MAYBE GOLD
    //this.initializeAdSlots(); // OLD BUT MAYBE GOLDinitalize ad slots after details have been set up
  }

  setupAllDetails() {
    const typedata = this.data.typedata;

    this.keyInfo = [
      { title: 'Merke', value: typedata.brand },
      { title: 'Modell', value: typedata.model },
      { title: 'Variant', value: typedata.variant },
      { title: 'Chassis type', value: typedata.chassisType },
      { title: 'Type', value: typedata.category },
      { title: 'Type', value: typedata.boatType },
      { title: 'Kategori', value: typedata.subCategory },
      { title: 'Årsmodell', value: typedata.yearmodel },
      { title: 'Km. stand', value: typedata.mileage, valueFormat: 'bigNumber', suffix: ' km' },
      { title: 'Reg. nr.', value: typedata.regNo && !typedata.hideRegNo ? typedata.regNo.toUpperCase() : null },
      ]; 

    this.typeDetails = [
      { title: 'Reg. nr.', value: typedata.regNo && !typedata.hideRegNo ? typedata.regNo.toUpperCase() : null },
      { title: 'Drivstoff', value: typedata.fueltype },
      { title: 'Material', value: typedata.boatMaterial },
      { title: 'Motor inkludert', value: typedata.motorIncluded },
      { title: 'Motortype', value: typedata.motorType },
      { title: 'Kjøreklasse', value: typedata.regClass },
      { title: 'Hjuldrift', value: typedata.wheeldrive },
      { title: 'Hjuldrift beskr.', value: typedata.wheeldriveDesc },
      { title: 'Karosseri', value: typedata.chassis },
      { title: 'usageTime', value: typedata.usageTime },
      { title: 'Motormerke', value: typedata.motorBrand },
      { title: 'Girkasse', value: typedata.transmission },
      { title: 'Girkasse beskr.', value: typedata.transmissionDesc },
      { title: 'Sylindervolum', value: typedata.engineCCM, valueFormat: 'bigNumber', suffix: ' ccm' },
      { title: 'Rekkevidde (elbil)', value: typedata.elBatteryRange, valueFormat: 'bigNumber', suffix: ' km' },
      { title: 'Batt. kapasitet (elbil)', value: typedata.elBatteryCapacity, valueFormat: 'bigNumber', suffix: ' kWh' },
      { title: 'HK', value: typedata.enginePower, valueFormat: 'bigNumber' },
      { title: 'Gjennomsnitt forbruk', value: typedata.fuelConsumptionAverage, suffix: ' l/mil' },
      { title: 'CO2-utslipp', value: typedata.co2gkm, suffix: ' g/km' },
      { title: 'Egenvekt', value: typedata.weight, valueFormat: 'bigNumber', suffix: ' kg' },
      { title: 'Lengde', value: typedata.length, valueFormat: 'bigNumber', suffix: ' cm' },
      { title: 'Bredde', value: typedata.width, valueFormat: 'bigNumber', suffix: ' cm' },
      { title: 'Maksvekt tilhenger m/brems', value: typedata.maxTrailerWeightWithBrakes, valueFormat: 'bigNumber', suffix: ' kg'},
    ];

    this.salesDetails = [
      { title: 'Garanti', value: typedata.warrantyText },
      { title: 'Garanti, km.', value: typedata.warrantyKm, valueFormat: 'bigNumber' },
      { title: 'Garanti, måneder', value: typedata.warrantyMonths },
      this.getControlDateFormatted(typedata.euControlDate, 'EU-kontroll'),
      this.getControlDateFormatted(typedata.nafControlDate, 'NAF-kontroll'),
      { title: 'Avgiftsklasse', value: typedata.regClass },
      { title: 'Førstegangsreg.', value: typedata.firstReg },
      { title: 'Ant. eiere', value: typedata.numOwners },
      { title: 'Ant. dører', value: typedata.numDoors },
      { title: 'Ant. seter', value: typedata.numSeats },
      { title: 'Ant. soveplasser', value: typedata.numSleepers },
      { title: 'Farge', value: typedata.colour },
      { title: 'Farge beskr.', value: typedata.colourDesc },
      { title: 'Innv. farge', value: typedata.colorInterior },
      { title: 'Sengeløsninger', value: typedata.bedTypes },
      { title: 'Varmesystem', value: typedata.heatingSystem },
      { title: 'Chassis-fabrikant', value: typedata.chassisManufacturer },
      { title: 'Selgers referanse', value: !this.data.isPrivate ?this.data.extId : null },
    ];

  }

  /**
   * Get a formatted control date for display on the prospect page.
   */
  getControlDateFormatted(dateValue: Date | string | null, name: string) {
    if (dateValue === null || !dateValue) {
      return { title: null, value: null };
    }

    const dateObj = new Date(dateValue);
    const isFuture = new Date() < dateObj;
    const dateFormat = isFuture ? 'MMMM yyyy' : 'shortDate';

    return {
      title: isFuture ? `Neste ${name}` : `Forrige ${name}`,
      value: formatDate(dateValue, dateFormat, 'nb'),
    };
  }

  backToSearch() {
    this.router.navigate(['/'], {
      queryParams: this.cs.got() ? this.cs.get().args.toParams() : { type: this.data.type }
    });
  }

  hasSearchContext() {
    return this.cs.got();
  }

  onSwipe(evt) {
    if (Math.abs(evt.deltaX) < 80) {
      return;
    }

    const right = evt.deltaX > 0;
    const link = right ? this.prevLink : this.nextLink;

    if (link) {
      this.router.navigate([link]);
    }

    return true;
  }

  urlCopied() {
    this.notifications.successMessage('Lenken er kopiert til din utklippstavle.');
  }

  /**
   * Save or delete this as a favourite.
   */
  toggleSaved() {
    // Check if the user is logged in or not.
    this.authenticationService.isLoggedIn().subscribe(
      (result) => {
        // Not logged in - show dialog
        if (!result) {
          const options = {
            title: 'For å lagre favoritter må du logge inn eller opprette bruker',
            confirmText: 'Gå til innlogging/registrering',
          };

          this.dialogService.questionDialog(options).afterClosed().subscribe(result => {
            if (result) {
              this.router.navigate(['/bruker']);
            }
          });

          return;
        }

        // Logged in - save or delete favourite
        if (this.saved) {
          this.savedProspectsService.deleteProspect(this.id, this.data.mainImageId, this.data.type).subscribe(
            (result) => {
              this.saved = false;
              this.notifications.successMessage('Fjernet fra dine favoritter.');
            }
          );
        } else {
          this.savedProspectsService.saveProspect(this.id, this.data.mainImageId, this.data.type).subscribe(
            (result) => {
              this.saved = true;
              this.notifications.successMessage('Lagret i dine favoritter.');
            }
          );
        }
      }
    );
  }

  closePreview() {
    window.close();
  }

  showFullscreenImages(imageIndex) {
    this.ngxSmartModalService.getModal('fullscreenImages').open();
    this.currentImage = imageIndex;

    setTimeout(() => {
      this.fullscreenView = true;
    }, 300);
  }

  getWrapperClass(): string {
    const numberOfItems = this.getNumberOfItems();
    return `external-wrapper items-${numberOfItems}`;
  }

  getNumberOfItems(): number {
    let count = 0;
    // Get direct children elements of external-wrapper
    const externalWrapper = document.querySelector('.external-wrapper');
    if (externalWrapper) {
      // Count the number of direct children
      count = externalWrapper.childElementCount;
    }
    return count;
  }

  ngOnDestroy() {
    this.metatags.clear();
  }
}
